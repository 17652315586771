import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { CloseButton, Col, Offcanvas } from "react-bootstrap";
import { Form, Spinner } from "reactstrap";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { MenuHeight } from "../NewDashboard/constant";
import axios from "axios";
import common from "../../Components/Common/common";
import { useSelector } from "react-redux";
import { getInternalUsersData } from "../../helpers/Help/CommomApi";

const OnlineAssetModal = ({
  show,
  validation,
  handleClose,
  postIsLoading,
  assetTypeOpt,
  setAssetTypeOpt,
  websiteGroupOptions,
  websiteGroupOpt,
  setWebsiteGroupOpt,
  id,
  isAssetDetail,
}) => {
  const userData = useSelector((state) => state.userDetails.userData);

  const [defaultSelectedOption, setDefaultSelectedOption] = useState("");
  const [adminOptions, setAdminOptions] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const handleAdminChange = (selectedOption) => {
    setSelectedAdmin(selectedOption.target.value);
    validation.setFieldValue("parent_id", selectedOption.target.value);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const assetsOptions = [
    { label: "App", value: "App" },
    { label: "Website", value: "Website" },
  ];

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "In-Active", value: "In-Active" },
  ];

  const getDepartmentName = async (id) => {
    try {
      let response = await axios.get(
        `websiteCategories/get-category-name/${id}`
      );
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (userData?.role !== "Admin" && userData?.role !== "Super Admin") {
        const value = response.data ? response.data.id : "";
        validation.setFieldValue("department_id", value);
        setDefaultSelectedOption({
          value: response.data?.id,
          label: response.data?.label,
        });
      }
    } catch (error) {
      console.error("Error ->", error.message);
    }
  };

  useEffect(() => {
    if (userData?.role !== "Admin" && userData?.role !== "Super Admin") {
      getDepartmentName(userData?.department_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const fetchAllAdmins = async () => {
    const response = await getInternalUsersData();
    const responseData = common.decrypt(response.data);
    const allAdminsData = responseData.filter((item) => item.role === "Admin");
    let Options = allAdminsData.map((item) => ({
      value: item.id,
      label: item.user_name,
    }));
    setAdminOptions(Options);
  };

  useEffect(() => {
    if (userData?.role === "Super Admin") fetchAllAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Box>
      <Offcanvas
        style={{ width: "51rem" }}
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {id ? <> Edit Asset </> : <> Add Asset </>}{" "}
          </Offcanvas.Title>
          <CloseButton onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form
            onSubmit={validation.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Box className="row">
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <TextField
                  fullWidth
                  required
                  label="Website Short Name"
                  name="website_short_name"
                  placeholder="Enter Website Short name"
                  value={validation.values.website_short_name}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                />
                {validation.errors.website_short_name &&
                validation.touched.website_short_name ? (
                  <span className="error-text">
                    {validation.errors.website_short_name}
                  </span>
                ) : null}
              </Box>
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <TextField
                  fullWidth
                  required
                  name="website_full_name"
                  placeholder="Enter Website Full name"
                  label="Website Full Name"
                  value={validation.values.website_full_name}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                />
                {validation.errors.website_full_name &&
                validation.touched.website_full_name ? (
                  <span className="error-text">
                    {validation.errors.website_full_name}
                  </span>
                ) : null}
              </Box>
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <TextField
                  fullWidth
                  name="organization"
                  placeholder="Enter Organization"
                  label="Enter Organization"
                  value={validation.values.organization}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                />
                {validation.errors.organization &&
                validation.touched.organization ? (
                  <span className="error-text">
                    {validation.errors.organization}
                  </span>
                ) : null}
              </Box>
              {/* Admins dropdown */}
              {userData?.role === "Super Admin" && !id && (
                <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                  <FormControl fullWidth>
                    <InputLabel>Admin *</InputLabel>
                    <Select
                      label="admin *"
                      fullWidth
                      value={
                        adminOptions.find(
                          (option) => option.value === selectedAdmin
                        )?.value || ""
                      }
                      onChange={handleAdminChange}
                      onBlur={validation.handleBlur}
                      MenuProps={MenuHeight}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select an option</em>;
                        }
                        return adminOptions.find(
                          (option) => option.value === selected
                        )?.label;
                      }}
                      styles={customStyles}
                    >
                      {adminOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel>Asset type *</InputLabel>
                  <Select
                    label="Asset type *"
                    fullWidth
                    value={
                      assetsOptions.find(
                        (option) =>
                          option.value === validation.values.assets_type
                      )?.value || ""
                    }
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      const selectedOption = assetsOptions.find(
                        (option) => option.value === selectedValue
                      );
                      validation.setFieldValue("assets_type", selectedValue);
                      setAssetTypeOpt(selectedOption);
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <>Select asset type</>;
                      }
                      return assetsOptions.find(
                        (option) => option.value === selected
                      )?.label;
                    }}
                  >
                    {assetsOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {validation.errors.assets_type &&
                  validation.touched.assets_type && (
                    <span className="error-text">
                      {" "}
                      {validation.errors.assets_type}
                    </span>
                  )}
              </Box>
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <TextField
                  fullWidth
                  required
                  name="website_url"
                  label="Website URL"
                  placeholder="Enter Website URL"
                  value={validation.values.website_url}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  InputProps={{
                    readOnly: isAssetDetail ? true : false,
                  }}
                />
                {validation.errors.website_url &&
                validation.touched.website_url ? (
                  <span className="error-text">
                    {validation.errors.website_url}
                  </span>
                ) : null}
              </Box>
              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel>Website category *</InputLabel>
                  <Select
                    label="Website category *"
                    fullWidth
                    value={
                      websiteGroupOptions.find(
                        (option) =>
                          option.id === validation.values.department_id
                      )?.id ||
                      defaultSelectedOption?.value ||
                      ""
                    }
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      const selectedOption = websiteGroupOptions.find(
                        (option) => option.id === selectedValue
                      );
                      validation.setFieldValue("department_id", selectedValue);
                      setWebsiteGroupOpt(selectedOption);
                    }}
                    MenuProps={MenuHeight}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <>Website category</>;
                      }
                      return websiteGroupOptions.find(
                        (option) => option.id === selected
                      )?.label;
                    }}
                    disabled={
                      !(
                        userData?.role === "Admin" ||
                        userData?.role === "Super Admin"
                      )
                    }
                  >
                    {websiteGroupOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {validation.errors.department_id &&
                  validation.touched.department_id && (
                    <span className="error-text">
                      {validation.errors.department_id}
                    </span>
                  )}
              </Box>

              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <TextField
                  fullWidth
                  required
                  name="ga_view_id"
                  placeholder="Enter Google Analytics Property ID"
                  label="Google Analytics Property ID"
                  value={validation.values.ga_view_id}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  InputProps={{
                    readOnly: isAssetDetail ? true : false,
                  }}
                />
                {validation.errors.ga_view_id &&
                  validation.touched.ga_view_id && (
                    <span className="error-text">
                      {validation.errors.ga_view_id}
                    </span>
                  )}
              </Box>

              <Box className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel>status</InputLabel>
                  <Select
                    label="status"
                    fullWidth
                    value={
                      statusOptions.find(
                        (option) => option.value === validation.values.status
                      )?.value || ""
                    }
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      validation.setFieldValue("status", selectedValue);
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select an option</em>;
                      }
                      return statusOptions.find(
                        (option) => option.value === selected
                      )?.label;
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {validation.errors.status && validation.touched.status && (
                  <span className="error-text">{validation.errors.status}</span>
                )}
              </Box>
              <Box className="col-lg-12 mb-3">
                <TextField
                  id="outlined-size-small"
                  name="short_description"
                  placeholder="Enter Short Description"
                  label="Short Description"
                  value={validation.values.short_description}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                />
                {validation.errors.short_description &&
                  validation.touched.short_description && (
                    <span className="error-text">
                      {validation.errors.short_description}
                    </span>
                  )}
              </Box>
              {!id && (
                <Box className="col-lg-12 mb-3 d-flex flex-wrap">
                  <Switch
                    checked={validation.values.is_third_party_test} // Bind the value to validation
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      validation.setFieldValue(
                        "is_third_party_test",
                        isChecked
                      ); // Update validation value
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Box className="ms-1 mt-2">
                    <span className="info-text">
                      <strong>Note:</strong> Enable this toggle to fetch or scan
                      the details (Performance, Security). Please note that this
                      will count against your package limit.
                    </span>
                  </Box>
                </Box>
              )}
              <Col lg={12}>
                <Button
                  className="btn-dark"
                  type="submit"
                  disabled={postIsLoading}
                >
                  {postIsLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button className="btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
              {postIsLoading && (
                <Box
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
                  role="alert"
                >
                  Please wait. while we are processing your data.
                </Box>
              )}
            </Box>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Box>
  );
};

export default OnlineAssetModal;
