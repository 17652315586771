import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebounce } from "../../EmailLogs/EmailLogs";

const MobileNumberComponent = ({
  setCountryViceNumberLength,
  validation,
  selectedCountryOption = { name: "", phone: "" },
  setSelectedCountryOption = null,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryDetails, setCountryDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedCountrySearch = useDebounce(searchQuery, 500);

  const getPhoneCode = (phoneString) => {
    try {
      // Ensure that phoneString is a string and parse it if it's in JSON format
      const phoneArray = phoneString ? JSON.parse(phoneString) : [];
      return phoneArray[0] || "";
    } catch (error) {
      console.error("Error parsing phone code:", error);
      return ""; // Default to empty string if there's an error
    }
  };

  useEffect(() => {
    if (selectedCountryOption?.name) {
      setSearchQuery(selectedCountryOption.name || "");
      setSelectedCountry({
        name: selectedCountryOption?.name || "",
        phone: selectedCountryOption?.phone || "[]",
      });
    }
  }, [selectedCountryOption]);

  useEffect(() => {
    if (selectedCountryOption?.name && countries.length > 0) {
      const countryInfo = countries.find(
        (item) => item.name === selectedCountryOption.name
      );
      if (countryInfo) {
        setSelectedCountry(countryInfo);
        setCountryDetails(countryInfo);
        setCountryViceNumberLength(countryInfo.phoneLength);
        setSearchQuery(countryInfo.name);
      }
    }
  }, [selectedCountryOption, countries, setCountryViceNumberLength]);

  const loadCountries = async (query = "", reset = false) => {
    if (loading) return;

    setLoading(true);
    try {
      const response = await axios.get("/country/get-countries", {
        params: { page: reset ? 1 : page, limit: 10, query },
      });
      const newData = response?.data || [];
      if (reset) {
        setCountries(newData);
        setPage(2);
      } else {
        setCountries((prev) => [...prev, ...newData]);
        setPage((prev) => prev + 1);
      }
      setHasMore(newData.length === 10);
    } catch (error) {
      console.error("Failed to load countries:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCountries(debouncedCountrySearch || "", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCountrySearch]);

  const handleCountryChange = (event, newValue) => {
    if (!newValue) {
      setSelectedCountry(null);
      setCountryDetails({});
      setCountryViceNumberLength(0);
      validation.setFieldValue("country", "");
      validation.setFieldValue("phone", "");
      setSelectedCountryOption && setSelectedCountryOption({ name: "" });
      return;
    }

    setSelectedCountry(newValue);
    setCountryDetails(newValue);
    setCountryViceNumberLength(newValue.phoneLength);
    validation.setFieldValue("country", newValue.name);
    validation.setFieldValue("phone", "");
    setSelectedCountryOption &&
      setSelectedCountryOption({
        name: newValue.name,
        phone: newValue.phone,
      });
  };

  const handleSearch = (event, newInputValue) => {
    setSearchQuery(newInputValue?.toLowerCase() || "");
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Autocomplete
        value={selectedCountry}
        options={Array.isArray(countries) ? countries : []}
        getOptionLabel={(option) => {
          const phoneCode = getPhoneCode(option?.phone || "[]");
          return `${phoneCode}`;
        }}
        filterOptions={(options, state) =>
          options.filter(
            (option) =>
              option.name
                .toLowerCase()
                .includes(state.inputValue.toLowerCase()) ||
              getPhoneCode(option.phone)
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
          )
        }
        loading={loading}
        onInputChange={handleSearch}
        onChange={handleCountryChange}
        // isOptionEqualToValue={(option, value) => option.phone === value.phone}
        isOptionEqualToValue={(option, value) =>
          option.name === value.name || option.phone === value.phone
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select country"
            fullWidth
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: <>{params.InputProps.startAdornment}</>,
              style: { height: "50px", width: "200px" },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={option.image || ""}
                alt={option.name || "Country"}
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                loading="lazy"
              />
              <span>{option.name || "Unknown"}</span>
              {option.phone && (
                <b style={{ marginLeft: 4 }}>({getPhoneCode(option.phone)})</b> // Safely display phone code
              )}
            </div>
          </li>
        )}
        ListboxProps={{
          onScroll: (event) => {
            const target = event.target;
            const bottom =
              Math.abs(
                target.scrollHeight - target.clientHeight - target.scrollTop
              ) <= 1;

            if (bottom && !loading && hasMore) {
              loadCountries(searchQuery);
            }
          },
          style: { maxHeight: 300 },
        }}
      />
      {/* <TextField
        fullWidth
        name="phone"
        type="tel"
        variant="outlined"
        sx={{ height: "50px" }}
        onChange={validation.handleChange}
        value={validation.values.phone || ""}
        onBlur={validation.handleBlur}
        error={Boolean(validation.errors.phone && validation.touched.phone)}
        helperText={
          validation.errors.phone && validation.touched.phone
            ? validation.errors.phone
            : ""
        }
        placeholder={
          countryDetails.phoneLength
            ? `Enter your phone number (${countryDetails.phoneLength})`
            : "Enter your phone number"
        }
        InputProps={{
          style: { height: "50px" },
        }}
        disabled={!selectedCountry || !selectedCountry?.name}
      /> */}
      <TextField
        fullWidth
        name="phone"
        type="tel"
        variant="outlined"
        sx={{ height: "50px" }}
        onChange={(e) => {
          const value = e.target.value;
          const maxLength = countryDetails?.phoneLength || Infinity; // Get max length from countryDetails

          // Allow only numeric input and limit the length
          if (/^\d*$/.test(value) && value.length <= maxLength) {
            validation.handleChange(e); // Update Formik value only for valid input
          }
        }}
        value={validation.values.phone || ""}
        onBlur={validation.handleBlur}
        error={Boolean(validation.errors.phone && validation.touched.phone)}
        helperText={
          validation.errors.phone && validation.touched.phone
            ? validation.errors.phone
            : ""
        }
        placeholder={
          countryDetails.phoneLength
            ? `Enter your phone number (${countryDetails.phoneLength})`
            : "Enter your phone number"
        }
        InputProps={{
          style: { height: "50px" },
        }}
        disabled={!selectedCountry || !selectedCountry?.name}
      />
    </div>
  );
};

export default MobileNumberComponent;
