import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addComplianceSuccess,
  addComplianceFail
} from "./action";

import {
  ADD_COMPLIANCE
} from "./actionType";

import axios from 'axios';

// Add Developers Profile API Call
const addComplianceAPI = async (values) => {
  try {
    let data = new FormData();
    data.append("website_guideline_id", values.website_guideline_id)
    data.append("branding_guideline_id", values.branding_guideline_id)
    // data.append("website_policy", values.website_policy)
    data.append("developers_id", values.developers_id)
    data.append("other_email", values.other_email)

    const response = await axios.post(
      "/compliance/post-compliance",
      data
    )
    if (response.status === 400) throw new Error(response.error.message);
    return response.data;
  } catch (error) {
    throw error.message;
  }
};

// Add Compliance Saga
function* addCompliance(action) {
  try {
    yield call(addComplianceAPI, action.payload);
    yield put(addComplianceSuccess());
  } catch (error) {
    yield put(addComplianceFail(error));
  }
}

export function* watchAddCompliance() {
  yield takeEvery(ADD_COMPLIANCE, addCompliance);
}

function* ComplianceSaga() {
  yield all([
    fork(watchAddCompliance),
  ]);
}

export default ComplianceSaga;
