import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, Nav } from "react-bootstrap";
import { Box, Skeleton } from "@mui/material";
import {
  BrowserIcon,
  DashboardIcon,
  DiscoverOutlineIcon,
} from "../../../../Components/Common/SvgIcons";
import common from "../../../../Components/Common/common";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { getPerformanceData } from "../../../../helpers/Help/CommomApi";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import RunScan from "./runScan";

const Performance = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const [scores, setGtmetrixData] = useState(null);
  const [typedata, setType] = useState("1");
  const [timeFilter, setTimeFilter] = useState("year");
  const [isLoading, setIsLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  const scoresRef = useRef(scores);

  const getMsOrSec = (value) =>
    value < 1000 ? value + "ms" : (value / 1000).toFixed(1) + "s";

  useEffect(() => {
    scoresRef.current = scores;
  }, [scores]);

  const handleClickDays = (days) => {
    if (days) {
      setTimeFilter(days);
    }
  };

  const requestOptions = useMemo(() => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("assets_id", props.assetId);
    if (typedata !== undefined) {
      urlencoded.append("system_type", typedata.toString());
    }
    if (timeFilter !== undefined) {
      urlencoded.append("time_filter", timeFilter);
    }
    return {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: urlencoded,
      redirect: "follow",
    };
  }, [props.assetId, timeFilter, typedata, token]);

  const fetchGtmetrix = useCallback(async () => {
    try {
      setSkeleton(true);
      let url = `${process.env.REACT_APP_BASE_URL}asset/performance`;
      let gtmetrix = await fetch(url, requestOptions);
      let data1 = await gtmetrix.json();
      const decryptedData = common.decrypt(data1.data);
      data1.data = decryptedData;

      if (data1.status === "success") {
        setGtmetrixData(data1.data);
        props.onDataReceived(data1.data);
        setSkeleton(false);
      } else if (data1.status === "fail") {
        throw new Error("Failed to fetch GTmetrix data");
      }
    } catch (error) {
      console.error("Error in fetchGtmetrix:", error);
    } finally {
      setSkeleton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOptions]);

  // const memoizedGtmetrixData = useMemo(() => {
  //   return scores;
  // }, [scores]);

  useEffect(() => {
    fetchGtmetrix();
  }, [fetchGtmetrix]);

  //Polling using the useEffect
  useEffect(() => {
    let intervalId;
    const startPolling = () => {
      intervalId = setInterval(() => {
        if (scoresRef.current === null) fetchGtmetrix();
        else clearInterval(intervalId);
      }, 30000);
    };

    // Check if the API data is not yet fetched and start polling
    if (props?.assetsData?.isAPIFetched === false) {
      startPolling();
    } else if (props?.assetsData?.isAPIFetched === true && intervalId) {
      // Stop polling when isAPIFetched becomes true
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props?.assetsData?.isAPIFetched, fetchGtmetrix]);

  const intervalId = useRef(null);

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const getRunTestApiData = () => {
    setIsLoading(true);
    getPerformanceData({
      assetId: props.assetId,
    })
      .then((response) => {
        fetchGtmetrix();
        ToastMessage.Success("Data fetched successfully!");
      })
      .catch((error) => {
        ToastMessage.Error("Failed to fetch data. Please try again.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const MetricsDashboardNav = ({ handleClickDays, timeFilter }) => {
    return (
      <Nav className="nav nav-pills nav-justified mb-3 w-100" role="tablist">
        <Nav.Item>
          <button
            onClick={() => handleClickDays("week")}
            // className="nav-link text-truncate"
            className={`nav-link text-truncate ${
              timeFilter === "week" ? "active" : ""
            }`}
            title="7d"
            data-bs-toggle="tab"
            role="tab"
          >
            Weekly
          </button>
        </Nav.Item>
        <Nav.Item className="nav-item waves-effect waves-light">
          <button
            onClick={() => handleClickDays("month")}
            // className="nav-link text-truncate"
            className={`nav-link text-truncate ${
              timeFilter === "month" ? "active" : ""
            }`}
            title="30d"
            data-bs-toggle="tab"
            role="tab"
          >
            Monthly
          </button>
        </Nav.Item>
        <Nav.Item>
          <button
            onClick={() => handleClickDays("year")}
            // className="nav-link text-truncate active"
            className={`nav-link text-truncate ${
              timeFilter === "year" ? "active" : ""
            }`}
            title="1year"
            data-bs-toggle="tab"
            role="tab"
          >
            Yearly
          </button>
        </Nav.Item>
      </Nav>
    );
  };

  const isDataLoaded =
    typeof scores?.test_id === "number" ||
    props?.assetsData?.isAPIFetched === true;

  return (
    <>
      <Card className="main-card">
        <Card.Header>
          <h4 className="card-title">Performance</h4>
        </Card.Header>

        <Card.Body className="nv_tab_com">
          <>
            {skeleton ? (
              <>
                <Skeleton variant="rectangular" width="100%" height={50} />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={50}
                  style={{ marginTop: 10 }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={70}
                  style={{ marginTop: 10 }}
                />
                <Box className="d-flex align-content-start justify-content-between gap-1 mt-3">
                  {[1, 2, 3].map((index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      style={{
                        width: "30%", // Set width for the card
                        height: "150px", // Set height for the card
                        margin: "10px", // Add margin around each card
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc", // Optional: border for visual separation
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Skeleton variant="circular" width={40} height={40} />
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height={40}
                        >
                          <Skeleton variant="text" width={70} height={30} />
                        </Box>
                        <Skeleton
                          variant="text"
                          width={100}
                          height={20}
                          style={{ marginTop: 5 }}
                        />
                      </Box>
                    </Card>
                  ))}
                </Box>
              </>
            ) : isDataLoaded ? (
              <Box className="nv_tab_com">
                <MetricsDashboardNav
                  handleClickDays={handleClickDays}
                  timeFilter={timeFilter}
                />
                <Nav
                  className="nav nav-pills nav-justified mb-3 w-100"
                  role="tablist"
                >
                  <Nav.Item className="w-100">
                    <button
                      className="nav-link btn btn-primary w-100"
                      title="desktop"
                      data-bs-toggle="tab"
                      role="tab"
                      onClick={() => setType(1)}
                    >
                      Desktop
                    </button>
                  </Nav.Item>
                </Nav>

                <Box className="tab-content mt-2">
                  <Box
                    className="tab-pane active"
                    id="pill-justified-home-1"
                    role="tabpanel"
                  >
                    <Box>
                      <h6 className="fs-16 fw-semibold text-primary">
                        Page Speed Score
                      </h6>
                      <Box className="progress animated-progress custom-progress progress-label">
                        <Box
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${scores?.pagespeed_score}%` }}
                          aria-valuenow={scores?.pagespeed_score}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <Box className="label">
                            {scores?.pagespeed_score} <span>%</span>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="d-flex align-items-center justify-content-between text-muted fw-normal mt-3">
                        <span>Last Test</span>
                        <span>
                          {
                            formatDateTime(
                              scores?.day,
                              dateFormat,
                              timeZone,
                              timeFormat
                            ).timeZone.formattedDateFinal
                          }
                        </span>
                      </Box>
                    </Box>
                    <Box className="d-flex align-content-start justify-content-between gap-1 mt-3">
                      <Box className="pagespeed-card">
                        <DashboardIcon />
                        <h4 className="time">
                          {scores?.onload_time
                            ? `${getMsOrSec(scores.onload_time)}`
                            : "-"}
                        </h4>
                        <p className="title">Onload Time</p>
                      </Box>
                      <Box className="pagespeed-card">
                        <DiscoverOutlineIcon />
                        <h4 className="time">
                          {scores?.page_load_time
                            ? `${getMsOrSec(scores.page_load_time)}`
                            : "-"}
                        </h4>
                        <p className="title"> Page Load Time</p>
                      </Box>
                      <Box className="pagespeed-card">
                        <BrowserIcon />
                        <h4 className="time">
                          {scores?.connect_duration
                            ? `${getMsOrSec(scores.connect_duration)}`
                            : "-"}
                        </h4>
                        <p className="title">Connect Duration</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
                <div className="card-body text-center">
                  <h5 className="text-muted">
                    We are currently assessing the website's performance. The
                    data will be displayed shortly.
                  </h5>
                </div>
              </div>
            )}
          </>

          {role && <RunScan loading={isLoading} onClick={getRunTestApiData} />}

          {isLoading && (
            <Box
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
              role="alert"
            >
              Please wait as we are running a scan
            </Box>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Performance;
