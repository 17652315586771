import React from "react";
import axios from "axios";

const DownloadPDF = ({ pdfUrl }) => {
  const handleDownload = async () => {
    try {
      // Fetch the PDF from the backend
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Ensure the response is a binary blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response], { type: "application/pdf" });

      // Create a download link and trigger it programmatically
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "downloaded_file.pdf"; // Specify the file name
      document.body.appendChild(link); // Append link to body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link after download
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <span className="btn btn-outline-primary btn-sm" onClick={handleDownload}>
      Download PDF
    </span>
  );
};

export default DownloadPDF;
