import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WebsiteSecurityHistory from "./WebsiteSecurityHistory";
import WebSecurityDetailPage from "../../../Reporting/webSecurityDetail/WebSecurityDetailPage";
import { WebSecuTabStyle } from "../../constant";
import { useLocation } from "react-router-dom";

const WebsiteSecurity = ({
  assetsData,
  securityData,
  refreshTestApi,
  userId,
  paramsId,
}) => {
  const [value, setValue] = useState("2");
  const location = useLocation();

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location?.state?.tabValueFor === "securityTab") {
      setValue("1");
      delete location?.state;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="websecurity">
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeTabs}
            variant="fullWidth"
            sx={WebSecuTabStyle}
          >
            <Tab label="Web. Security History" value="1" />
            <Tab label="Web. Security Scanning" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tab-panel">
          {typeof securityData?.id === "number" || assetsData?.isAPIFetched ? (
            <WebsiteSecurityHistory />
          ) : (
            <div className="card mt-5">
              <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                <div className="text-center">
                  <h5 className="text-muted">
                    We are in the process of scanning your website. Please be
                    patient as we prepare your report.
                  </h5>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel value="2" className="tab-panel">
          {typeof securityData?.id === "number" || assetsData?.isAPIFetched ? (
            <WebSecurityDetailPage
              refreshTestApi={refreshTestApi}
              paramsId={paramsId}
              userId={userId}
            />
          ) : (
            <div className="card mt-5">
              <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                <div className="text-center">
                  <h5 className="text-muted">
                    We are in the process of scanning your website. Please be
                    patient as we prepare your report.
                  </h5>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WebsiteSecurity;
