import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DownloadPDF from "../../../Components/Common/DownloadPdf";
import axios from "axios";
import { toast } from "react-toastify";

const BootstrapCards = ({
  user,
  color,
  mapKey,
  updateGuideline,
  handleDeleteAlert,
  hasPermission,
}) => {
  const authToken = localStorage.getItem("token");
  const [logoImage, setLogoImage] = useState("");

  const settingsData = useSelector(
    (state) =>
      state?.settingsData?.settingsData?.find(
        (a) => a?.field_name === "GUIDELINE_PDF_IMAGE"
      )?.field_value
  );

  useEffect(() => {
    setLogoImage(`${process.env.REACT_APP_BASE_URL}${settingsData}`);
  }, [settingsData]);

  const handleSecurePdfView = async (pdfId) => {
    try {
      const response = await axios.get("/branding-guideline/get-pdf-token", {
        params: { pdfId },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === "fail") {
        const { code } = response;
        switch (code) {
          case 404:
            toast.error("File not found");
            break;
          case 403:
            toast.error("Access Denied: Insufficient Permissions");
            break;
          case 401:
            toast.error("Unauthorized: Please log in again");
            break;
          default:
            toast.error("Cannot access PDF");
        }
        return;
      }

      const { token, urlToRedirect } = response.data;

      if (!token || !urlToRedirect) {
        throw new Error("Invalid server response");
      }

      window.open(`${urlToRedirect}?token=${token}`, "_blank");
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            toast.error("File not found");
            break;
          case 403:
            toast.error("Access Denied: Insufficient Permissions");
            break;
          case 401:
            toast.error("Unauthorized: Please log in again");
            break;
          default:
            toast.error("Cannot access PDF");
        }
      } else if (error.request) {
        toast.error("Network Error: Unable to connect to server");
      } else {
        toast.error(error.message || "An unexpected error occurred.");
      }
    }
  };
  return (
    <>
      <Box
        className="col-12 col-sm-6 col-md-6 col-xl-4 col-xxxl-3"
        key={mapKey}
      >
        <Card className="team-box h-100 mb-0">
          <span title={user.branding_guideline_title}>
            <Box className="thumbnail-container">
              <Box className="thumbnail">
                {user.branding_guideline_image != null ? (  
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user.branding_guideline_image}`}
                    alt="imgs"
                    className="img-fluid"
                  />
                ) : (
                  <img src={logoImage} alt="" className="img-fluid" />
                )}
              </Box>
            </Box>
          </span>
          <Card.Body className="p-4">
            {hasPermission &&
            (hasPermission?.canModify != null ||
              hasPermission.canDelete != null) ? (
              <>
                <Box className="team-settings">
                  <Box className="text-end dropdown">
                    <span
                      id="dropdownMenuLink2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="cursor-pointer"
                    >
                      <MoreVertOutlinedIcon />
                    </span>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      {hasPermission && hasPermission?.canModify != null ? (
                        <>
                          <li>
                            <span
                              onClick={() => updateGuideline(user)}
                              className="dropdown-item"
                            >
                              <span className="me-2 align-middle">
                                <EditOutlinedIcon />
                              </span>
                              Edit
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {hasPermission && hasPermission?.canDelete != null ? (
                        <>
                          <li
                            onClick={() =>
                              handleDeleteAlert(user.id, user.email)
                            }
                            className="dropdown-item"
                          >
                            <span className="me-2 align-middle">
                              <DeleteIcon />
                            </span>
                            Delete
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
            <Box className="team-content mb-3">
              <h4 className="pe-2">
                <a
                  // href={user.branding_guideline_pdf}
                  href={`${process.env.REACT_APP_BASE_URL}${user.branding_guideline_pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {user.branding_guideline_title}
                </a>
              </h4>
              <p>
                <a
                  // href={user.branding_guideline_pdf}
                  href={`${process.env.REACT_APP_BASE_URL}${user.branding_guideline_pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {user.branding_guideline_description}
                </a>
              </p>
            </Box>
            {user.branding_guideline_pdf && (
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2">
                <DownloadPDF
                  pdfUrl={`${process.env.REACT_APP_BASE_URL}${user.branding_guideline_pdf}`}
                />
                <span
                  onClick={() => handleSecurePdfView(user.id)}
                  className="btn btn-outline-primary btn-sm"
                >
                  View Guidelines
                </span>
              </div>
            )}
          </Card.Body>
        </Card>
      </Box>
    </>
  );
};

export default BootstrapCards;
