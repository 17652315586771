import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Form,
  Label,
  Row,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";

//import images

import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { socket } from "../../../Components/Common/socket";
import { TextField } from "@mui/material";
import common from "../../../Components/Common/common";
import axios from "axios";
// import { MenuHeight } from "../../NewDashboard/constant";

const EditDeveloperProfile = ({
  setUpdatedValue,
  updatedValue,
  hasPermission,
  onCancel,
}) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const params = useParams();
  const navigate = useNavigate();
  // const [data, setData] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [selectCoverImage, setSelectedCoverImage] = useState(null);
  // const [activeTab, setActiveTab] = useState("1");

  /////*  FOR SKILLS DROPDOWN  */////
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [unmatchedOptions, setUnmatchedOptions] = useState([]);
  const [skillsId, setSkillsId] = useState([]);

  // FOR ASSETS DROPDOWN
  const [selectedOptionsForAssets, setSelectedOptionsForAssets] = useState([]);
  const [unmatchedAssets, setUnmatchedAssets] = useState([]);
  const [allAssetsId, setAallAssetsId] = useState([]);
  const [postIsLoading, setPostIsLoading] = useState(false);

  // useEffect(() => {
  //   if (selectedOptionsForAssets.length > 0) {
  //     validation.setFieldValue(
  //       "asset_url_management",
  //       selectedOptionsForAssets &&
  //         selectedOptionsForAssets.length > 0 &&
  //         selectedOptionsForAssets.map((item) => item.value)
  //     );
  //   }
  // }, [selectedOptionsForAssets]);

  // useEffect(() => {
  //   if (selectedOptions.length > 0) {
  //     validation.setFieldValue(
  //       "skills",
  //       selectedOptions &&
  //         selectedOptions.length > 0 &&
  //         selectedOptions.map((item) => item.value)
  //     );
  //   }
  // }, [selectedOptions]);

  useEffect(() => {
    validation.setFieldValue(
      "asset_url_management",
      selectedOptionsForAssets.map((item) => item.value) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionsForAssets]);

  useEffect(() => {
    validation.setFieldValue(
      "skills",
      selectedOptions.map((item) => item.value) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const getUserData = useCallback(async () => {
    let url = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let result = await fetch(url, reqData);
    let response = await result.json();

    if (response.code === 403) {
      navigate("/auth-404-cover");
    }

    if (response.status === "success") {
      const decryptedData = common.decrypt(response.data);
      // setData(decryptedData);
      let data = decryptedData;
      validation.setValues({
        developers_profile_image: data ? data.developers_profile_image : "",
        developers_cover_image: data ? data.developers_cover_image : "",
        organization: data ? data.organization : "",
        user_name: data ? data.user_name : "",
        contact_no: data ? data.contact_no : "",
        skills: selectedOptions
          ? selectedOptions.length > 0
            ? selectedOptions.map((item) => item.value)
            : ""
          : "",
        email: data ? data.email : "",
        organization_url: data ? data.organization_url : "",
        designation: data ? data.designation : "",
        location: data ? data.location : "",
        projects: data ? data.projects : "",
        tasks: data ? data.tasks : "",
        asset_url_management: selectedOptionsForAssets
          ? selectedOptionsForAssets.length > 0
            ? selectedOptionsForAssets.map((item) => item.value)
            : // : data.asset_url_management
              ""
          : "",
        description: data ? data.description : "",
      });
    }
    if (response.status === "fail") {
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, token, navigate]);

  const getSkills = useCallback(async () => {
    let url = `${process.env.REACT_APP_BASE_URL}skills/get-skills`;
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let result = await fetch(url, reqData);
    let response = await result.json();

    if (response.status === "success") {
      // setSkills(response.data);
    }
    if (response.status === "fail") {
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
  }, [token]);

  useEffect(() => {
    getUserData();
    getSkills();
  }, [getSkills, getUserData]);

  const skillsEndPoint = `${process.env.REACT_APP_BASE_URL}skills/get-skills`;
  const newSkillsEndPoint = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;

  useEffect(() => {
    fetch(skillsEndPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const skillIds = responseData?.map((item) => ({
          label: item.skill_name,
          value: item.id,
        }));
        setSkillsId(skillIds);
        setUnmatchedOptions(skillIds);
        fetch(newSkillsEndPoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((newData) => {
            const responseData = common.decrypt(newData.data);
            newData.data = responseData;
            const newSkillIds = JSON.parse(newData.data.skills);
            const matchedOptions = skillIds.filter((option) =>
              newSkillIds.includes(option.value)
            );

            setSelectedOptions(matchedOptions);

            const selectedValues = matchedOptions.map((option) => option.value);
            const newUnmatchedOptions = skillIds.filter(
              (option) => !selectedValues.includes(option.value)
            );

            setUnmatchedOptions(newUnmatchedOptions);
          });
      });
  }, [skillsEndPoint, newSkillsEndPoint, token]);

  useEffect(() => {
    // Update unmatchedOptions when selectedOptions change
    const selectedValues = selectedOptions.map((option) => option.value);

    const newUnmatchedOptions = skillsId.filter(
      (option) => !selectedValues.includes(option.value)
    );
    setUnmatchedOptions(newUnmatchedOptions);
  }, [selectedOptions, skillsId]);

  const assetUrlManagementEndpoint = `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`;
  const newAssetUrlManagementEndpoint = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;

  useEffect(() => {
    fetch(assetUrlManagementEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const assetsIds = responseData?.map((item) => ({
          label: item.website_url,
          value: item.id,
        }));

        setAallAssetsId(assetsIds);
        setUnmatchedAssets(assetsIds);
        fetch(newAssetUrlManagementEndpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((newData) => {
            const responseData = common.decrypt(newData.data);
            newData.data = responseData;
            const newAssetIds = JSON.parse(newData.data.asset_url_management);
            const matchedOptions = assetsIds.filter((option) =>
              newAssetIds?.includes(option.value)
            );

            setSelectedOptionsForAssets(matchedOptions);

            const selectedAssets = matchedOptions.map((option) => option.value);
            const newUnmatchedOptions = assetsIds.filter(
              (option) => !selectedAssets?.includes(option.value)
            );

            setUnmatchedAssets(newUnmatchedOptions);
          });
      });
  }, [assetUrlManagementEndpoint, newAssetUrlManagementEndpoint, token]);

  useEffect(() => {
    // Update unmatchedOptions when selectedOptions change
    const selectedAssets = selectedOptionsForAssets.map(
      (option) => option.value
    );
    const newUnmatchedOptions = allAssetsId.filter(
      (option) => !selectedAssets.includes(option.value)
    );
    setUnmatchedAssets(newUnmatchedOptions);
  }, [selectedOptionsForAssets, allAssetsId]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      developers_profile_image: "",
      developers_cover_image: "",
      organization: "",
      user_name: "",
      contact_no: "",
      email: "",
      organization_url: "",
      designation: "",
      skills: [],
      location: "",
      projects: "",
      tasks: "",
      description: "",
      asset_url_management: [],
    },

    validationSchema: Yup.object().shape({
      organization: Yup.string().required("Please enter the Organization."),
      user_name: Yup.string()
        .min(5, "Please enter name should be 5 charcter long.")
        .max(255, "Please enter name should be 255 charcter long.")
        .required("Please enter the name."),
      contact_no: Yup.string()
        .matches(
          /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
          "Please enter a valid phone number"
        )
        .nullable(),
      email: Yup.string()
        .email("Please enter the valid email address.")
        .max(255)
        .required("Please enter the email address."),
      organization_url: Yup.string()
        .test("is-valid-url", "Invalid URL format.", (value) => {
          if (!value) {
            return true; // Allow empty values (you can change this if needed)
          }
          const urlPattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          return urlPattern.test(value);
        })
        .required("Please enter the organization URL."),
      asset_url_management: Yup.array()
        .min(1, "Please select at least one online asset.")
        .required("Please select the online assets."),
      designation: Yup.string()
        .min(5, "Designation should be 5 charcter long.")
        .max(20, "Designation should be 20 charcter long.")
        .nullable(),
      // .required("Please enter the designation."),
      skills: Yup.array()
        .required("Please select the skills.")
        .min(1, "Please select at least one skill."),
      location: Yup.string()
        .min(3, "Location should be 3 charcter long.")
        .max(50, "Location should be 50 charcter long")
        .nullable(),
      // .required("Please enter the location."),
      projects: Yup.number()
        .nullable()
        .min(1)
        .max(10000, "Projects must be less than or equal to 10000."),
      // .required("Please enter the projects."),
      tasks: Yup.number()
        .min(1)
        .max(10000, "Tasks must be less than or equal to 10000.")
        .nullable(),
      // .required("Please enter the tasks."),
      description: Yup.string()
        .min(4, "Description must be at least 4 characters")
        .max(255, "Description must be less than 255 characters")
        .nullable(),
    }),

    onSubmit: (values) => {
      validation.validateForm();
      updateUsers(values);
    },
  });

  const updateUsers = async (values) => {
    setPostIsLoading(true);
    try {
      let data = new FormData();
      if (
        values.developers_profile_image &&
        values.developers_profile_image.constructor === File
      ) {
        data.append(
          "developers_profile_image",
          values.developers_profile_image
        );
      }
      if (
        values.developers_cover_image &&
        values.developers_cover_image.constructor === File
      ) {
        data.append("developers_cover_image", values.developers_cover_image);
      }
      data.append("organization", values.organization);
      data.append("user_name", values.user_name);
      data.append("contact_no", values.contact_no || "");
      data.append("email", values.email);
      data.append("organization_url", values.organization_url);
      data.append("designation", values.designation || "");
      data.append("skills", values.skills);
      data.append("location", values.location || "");
      data.append("projects", values.projects);
      data.append("tasks", values.tasks);
      data.append("description", values.description);
      data.append("asset_url_management", values.asset_url_management);
      data.append("user_id", userId);

      // const formDataObject = {
      //   organization: values.organization,
      //   user_name: values.user_name,
      //   contact_no: values.contact_no || "",
      //   email: values.email,
      //   organization_url: values.organization_url,
      //   designation: values.designation || "",
      //   skills: values.skills,
      //   location: values.location || "",
      //   projects: values.projects.toString(),
      //   tasks: values.tasks.toString(),
      //   description: values.description,
      //   asset_url_management: values.asset_url_management,
      //   user_id: userId,
      // };

      // const encryptedData = common.encrypt(formDataObject);

      // data.append("encryptedData", encryptedData.data);

      let url = `${process.env.REACT_APP_BASE_URL}developer-profile/update-developer-profile/${params.id}`;
      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.status === "success") {
        ToastMessage.Success("Details have been successfully updated.");
        // setTimeout(() => {
        //   navigate(`/developer-profile-details/` + params.id);
        // }, 1000);
        socket.emit("updateDeveloperProfile");
        validation.resetForm();
        getUserData();
        onCancel();
        setUpdatedValue(!updatedValue);
      } else if (response.status === "fail") {
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastMessage.Error(
        "An unexpected error occurred. Please try again later."
      );
    } finally {
      setPostIsLoading(false);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const handleSelectAssetsChange = (selectedOptions) => {
    setSelectedOptionsForAssets(selectedOptions);
  };

  // const handleSelectAssetsChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const selectedOptions = unmatchedAssets.filter((option) =>
  //     value.includes(option.value)
  //   );
  //   setSelectedOptionsForAssets(selectedOptions);
  // };

  const uploadAdaptor = (loader) => {
    return {
      upload: async () => {
        try {
          const body = new FormData();
          const file = await loader.file;
          body.append("upload", file);
          const response = await axios.post(
            "developer-profile/uploadImage",
            body,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const { url } = response;
          if (!url) {
            throw new Error("No URL returned from server");
          }
          return {
            default: url,
          };
        } catch (error) {
          console.error(error.message);
        }
      },
    };
  };

  function uploadPlugins(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdaptor(loader);
    };
  }

  return (
    <>
      <React.Fragment>
        <ToastContainer closeButton={true} />
        <Form onSubmit={validation.handleSubmit}>
          <Row className="g-3">
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="user_name" className="form-label">
                  Full Name *
                </Label> */}
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="user_name"
                  placeholder="Enter Name"
                  label="Name *"
                  value={validation.values.user_name || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.errors.user_name && validation.touched.user_name
                  //     ? true
                  //     : false
                  // }
                />
                {validation.errors.user_name && validation.touched.user_name ? (
                  <span className="error-text">
                    {validation.errors.user_name}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="organizationInput" className="form-label">
                  Organization *
                </Label> */}
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Organization *"
                  name="organization"
                  value={validation.values.organization || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.errors.organization &&
                  //   validation.touched.organization
                  //     ? true
                  //     : false
                  // }
                  type="text"
                  id="organizationInput"
                  placeholder="Enter Organization"
                />
                {validation.errors.organization &&
                validation.touched.organization ? (
                  <span className="error-text">
                    {validation.errors.organization}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="phonenumberInput" className="form-label">
                  Contact No.
                </Label> */}
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Contact no."
                  name="contact_no"
                  value={validation.values.contact_no || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.errors.contact_no &&
                  //   validation.touched.contact_no
                  //     ? true
                  //     : false
                  // }
                  type="text"
                  id="phonenumberInput"
                  placeholder="Enter Contact no."
                />
                {validation.errors.contact_no &&
                validation.touched.contact_no ? (
                  <span className="error-text">
                    {validation.errors.contact_no}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="emailInput" className="form-label">
                  Email Address *
                </Label> */}
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Email *"
                  name="email"
                  value={validation.values.email || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.errors.email && validation.touched.email
                  //     ? true
                  //     : false
                  // }
                  type="email"
                  id="emailInput"
                  placeholder="Enter email"
                />
                {validation.errors.email && validation.touched.email ? (
                  <span className="error-text">{validation.errors.email}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="organization_urlInput" className="form-label">
                  Organization URL *
                </Label> */}
                <TextField
                  fullWidth
                  label="Organization url *"
                  name="organization_url"
                  value={validation.values.organization_url || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // invalid={
                  //   validation.errors.organization_url &&
                  //   validation.touched.organization_url
                  //     ? true
                  //     : false
                  // }
                  type="text"
                  id="organization_urlInput"
                  placeholder="Enter Organization URL"
                />
                {validation.errors.organization_url &&
                validation.touched.organization_url ? (
                  <span className="error-text">
                    {validation.errors.organization_url}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="designationInput" className="form-label">
                  Designation
                </Label> */}
                <TextField
                  fullWidth
                  label="Designation"
                  name="designation"
                  value={validation.values.designation || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // invalid={
                  //   validation.errors.designation &&
                  //   validation.touched.designation
                  //     ? true
                  //     : false
                  // }
                  type="text"
                  id="designationInput"
                  placeholder="Enter designation"
                />
                {validation.errors.designation &&
                validation.touched.designation ? (
                  <span className="error-text">
                    {validation.errors.designation}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                {/* <Label htmlFor="locationInput" className="form-label">
                  Location
                </Label> */}
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  value={validation.values.location || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // invalid={
                  //   validation.errors.location && validation.touched.location
                  //     ? true
                  //     : false
                  // }
                  type="text"
                  id="locationInput"
                  placeholder="Enter location"
                  // defaultValue="Lead Designer / Developer"
                />
                {validation.errors.location && validation.touched.location ? (
                  <span className="error-text">
                    {validation.errors.location}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                <TextField
                  fullWidth
                  label="Number of Projects"
                  name="projects"
                  value={validation.values.projects || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  id="projectsInput1"
                  placeholder="Enter number of Projects"
                  onWheel={(e) => e.target.blur()}
                />
                {validation.errors.projects && validation.touched.projects ? (
                  <span className="error-text">
                    {validation.errors.projects}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div>
                <TextField
                  fullWidth
                  label="Number of Tasks"
                  name="tasks"
                  value={validation.values.tasks || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  id="tasksInput1"
                  placeholder="Enter number of tasks"
                  onWheel={(e) => e.target.blur()}
                />
                {validation.errors.tasks && validation.touched.tasks ? (
                  <span className="error-text">{validation.errors.tasks}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xl={6}>
              <div>
                <Label
                  htmlFor="asset_url_managementInput"
                  className="form-label"
                >
                  Assign Websites *
                </Label>
                <Select
                  isMulti
                  name="asset_url_management"
                  options={unmatchedAssets}
                  onChange={handleSelectAssetsChange}
                  value={selectedOptionsForAssets}
                  onBlur={() => {
                    validation.setFieldTouched(
                      "asset_url_management",
                      true,
                      undefined //This was false before I turn it in undefined because unnecessary error was showing on touch
                    );
                  }}
                  invalid={
                    validation.errors.asset_url_management &&
                    validation.touched.asset_url_management
                      ? true
                      : false
                  }
                />
                {/* <FormControl fullWidth>
                  <InputLabel>Assign Website</InputLabel>
                  <Select
                    multiple
                    value={selectedOptionsForAssets}
                    onChange={(e) => handleSelectChange(e.target.value)}
                    label="Assign Website"
                    MenuProps={MenuHeight}
                    error={
                      validation.errors.asset_url_management &&
                      validation.touched.asset_url_management
                    }
                    renderValue={(selected) =>
                      selected.map((item) => item.label).join(", ")
                    }
                  >
                    {unmatchedAssets?.map((category) => (
                      <MenuItem key={category.id} value={category}>
                        {category.label}
                      </MenuItem>
                    ))}
                    {unmatchedAssets?.map((category, index) => (
                      <MenuItem
                        key={`${category.id}-${index}`}
                        value={category}
                      >
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {validation.errors.asset_url_management &&
                validation.touched.asset_url_management ? (
                  <span
                    type="invalid"
                    style={{ color: "red", display: "block" }}
                  >
                    {validation.errors.asset_url_management}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xl={6}>
              <div>
                <Label htmlFor="skillsInput" className="form-label">
                  Skills *
                </Label>
                <Select
                  isMulti
                  name="skills"
                  options={unmatchedOptions}
                  onChange={(selectedOption) => {
                    handleSelectChange(selectedOption);
                    // validation.setFieldTouched(
                    //   "skills",
                    //   true,
                    //   false
                    // );
                  }}
                  onBlur={() => {
                    validation.setFieldTouched("skills", true, false);
                  }}
                  // onBlur={validation.handleBlur}
                  value={selectedOptions}
                />

                {/* <FormControl fullWidth>
                  <InputLabel>Skills</InputLabel>
                  <Select
                    multiple
                    value={selectedOptions}
                    onChange={(e) => handleSelectChange(e.target.value)}
                    label="Skills"
                    MenuProps={MenuHeight}
                    error={
                      validation.errors.skills && validation.touched.skills
                    }
                    renderValue={(selected) =>
                      selected.map((item) => item.label).join(", ")
                    }
                  >
                    {unmatchedOptions.map((category) => (
                      <MenuItem key={category.id} value={category}>
                        {category.label}
                      </MenuItem>
                    ))}

                    {unmatchedOptions?.map((category, index) => (
                      <MenuItem
                        key={`${category.id}-${index}`}
                        value={category}
                      >
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                {/* {validation.errors.skills ? ( */}
                {validation.errors.skills && validation.touched.skills ? (
                  <span className="error-text">{validation.errors.skills}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={12}>
              <div className="pb-2">
                <Label
                  htmlFor="exampleFormControlTextarea"
                  className="form-label"
                >
                  Description
                </Label>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugins],
                  }}
                  editor={ClassicEditor}
                  data={validation.values.description || ""}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    validation.setFieldValue("description", editor.getData());
                    validation.setFieldTouched("description", true, false);
                  }}
                />
                {validation.errors.description &&
                validation.touched.description ? (
                  <FormFeedback
                    type="invalid"
                    className="d-block"
                    style={{ color: "red" }}
                  >
                    {validation.errors.description}
                  </FormFeedback>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                {hasPermission && hasPermission?.canModify ? (
                  <Button
                    className="btn btn-dark"
                    type="submit"
                    disabled={postIsLoading}
                  >
                    {postIsLoading ? (
                      <Spinner size="sm" variant="light" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                ) : (
                  ""
                )}
                {/* <Link to={`/developer-profile`} className="btn btn-primary">
                  Cancel
                </Link> */}
                <Button className="btn btn-primary" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default EditDeveloperProfile;
