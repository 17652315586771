import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import DataTable from "react-data-table-component";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LoopIcon from "@mui/icons-material/Loop";
import { Box } from "@mui/material";
import { CustomTableStyle } from "../../Components/Common/constants";
import axios from "axios";
import common from "../../Components/Common/common";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDebounce } from "../EmailLogs/EmailLogs";

const WebSecurityPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  let startDate, endDate;
  if (selectedDate) {
    [startDate, endDate] = selectedDate;
    startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setCurrentPage(1);
      setSelectedDate(selectedDates);
    }
  };

  const fetchData = async (
    startDate = null,
    endDate = null,
    query = "",
    page = 1,
    limit = 10
  ) => {
    // setIsLoading(true);
    try {
      let URL = `asset/websecurity-data?searchParams=${query}&page=${page}&limit=${limit}`;

      if (startDate && endDate) {
        URL += `&startDate=${startDate}&endDate=${endDate}`;
      }

      const { data, status, message } = await axios.get(URL);
      if (status === "fail") {
        throw new Error(message);
      }
      const responseData = common.decrypt(data);
      setTotalRowCount(responseData?.totalRecords);
      setData(responseData?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedQueryUrl = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedQueryUrl === query) {
      fetchData(startDate, endDate, debouncedQueryUrl, currentPage, perPage);
    }
  }, [startDate, endDate, debouncedQueryUrl, currentPage, perPage]);

  const handleReset = () => {
    setQuery("");
    setSelectedDate(null);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setPerPage(newPerPage);
  };

  const parsedScoreGrade = (x, type) => {
    let data = x && JSON.parse(x);
    let newData;
    if (type === "score") {
      newData = data?.score;
    }
    if (type === "grade") {
      newData = data?.grade;
    }
    return newData;
  };

  const columns = [
    {
      name: <span>Website</span>,
      selector: (row) => (
        <span onClick={() => navigate(`/web-security/${row.assets_id}`)}>
          <span className="fw-bold cursor-pointer">{row.url || "-"}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Score</span>,
      selector: (row) => (
        <span className="badge badge-soft-success">
          {parsedScoreGrade(row?.summary, "score") || "-"}
        </span>
      ),
    },
    {
      name: <span>Grade</span>,
      selector: (row) => (
        <span className="badge badge-soft-success">
          {parsedScoreGrade(row?.summary, "grade") || "-"}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Search by URL..."
                value={query}
                onChange={(e) => {
                  setCurrentPage(1);
                  setQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                className="form-control"
                options={{
                  mode: "range",
                  dateFormat: `${dateFormat}`,
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </button>
          </Box>
        </Box>

        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            // <DataTable
            //   columns={columns}
            //   data={filteredData}
            //   fixedHeader
            //   fixedHeaderScrollHeight="700px"
            //   pagination
            //   customStyles={CustomTableStyle}
            //   className="table-design"
            // />
            <DataTable
              columns={columns}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="700px"
              progressPending={isLoading}
              pagination
              paginationServer
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRowCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default WebSecurityPage;
