import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Label, Button, Spinner, Form } from "reactstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { detect } from "detect-browser";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addGeneralSettingData } from "../../../store/settings/action";
import ImgBlockedIp from "../../../assets/images/blockedIp.png";
import { addUserData } from "../../../store/actions";
import { Box } from "@mui/material";
import CopyrightRoundedIcon from "@mui/icons-material/CopyrightRounded";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import common from "../../../Components/Common/common";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CoverSignIn = () => {
  const navigate = useNavigate();
  const browser = detect();
  const dispatch = useDispatch();
  const storedRememberMe = sessionStorage.getItem("rememberedRememberMe");
  const rememberMeInitialValue = storedRememberMe
    ? storedRememberMe === "true"
    : false;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [getIp, setGetIp] = useState(null);
  const [blockedIps, setBlockedIps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(rememberMeInitialValue);
  const [loginAttempts, setLoginAttempts] = useState();
  const [errors, setErrors] = useState({
    email: "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("rememberedEmail");
    const storedPassword = sessionStorage.getItem("rememberedPassword");
    const storedRememberMe = sessionStorage.getItem("rememberedRememberMe");

    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
    }

    if (storedRememberMe) {
      setRememberMe(storedRememberMe === "true");
    }
  }, []);

  const validateForm = () => {
    let formErrors = {};

    const trimmedEmail = email ? email.trim() : "";

    if (!trimmedEmail) {
      formErrors.email = "Please enter email.";
    } else if (!common.validateEmail(trimmedEmail)) {
      formErrors.email = "Must be a valid email";
    }

    if (!password) {
      formErrors.password = "Please enter a password.";
    }

    setValidationErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleEmailBlur = () => {
    let emailError = "";
    if (!email) {
      emailError = "Please enter Email.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Must be a valid email";
    }
    setValidationErrors((prevErrors) => ({ ...prevErrors, email: emailError }));
  };

  const handlePasswordBlur = () => {
    let passwordError = "";
    if (!password) {
      passwordError = "Please enter password.";
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordError,
    }));
  };

  const generalSettingData = useSelector(
    (state) => state?.settingsData.generalSettingData
  );

  const siteName = generalSettingData?.find(
    (item) => item.field_name === "SITE_NAME"
  )?.field_value;
  const settingBlockedIp = generalSettingData?.find(
    (item) => item.field_name === "BLOCKED_IP"
  );
  const totalLoginAttempts = generalSettingData?.find(
    (item) => item.field_name === "MAX_LOGIN_ATTEMPTS"
  );

  const settingLimitesAccessIp = generalSettingData?.find(
    (item) => item.field_name === "LIMITED_POWERPANEL_ACCESS"
  );

  const parseLimitedAccess = settingLimitesAccessIp?.field_value
    ? JSON.parse(settingLimitesAccessIp?.field_value)
    : [];

  const hasMatchingLimitedAccess = parseLimitedAccess?.some(
    (item) => item === getIp
  );

  const getBlockedIps = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ip/get-blockedIp`
      );
      let blockedIpsdata = common.decrypt(data);
      blockedIpsdata = blockedIpsdata?.rows;
      setBlockedIps(blockedIpsdata);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getSettingData = async () => {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}settings/get-settings`
      );

      const decryptedData = common.decrypt(data);
      data = decryptedData;

      const settingData = data?.rows;

      const filterData = settingData.filter(
        (item) =>
          item.settings_category === "general" ||
          item.settings_category === "security"
      );
      dispatch(addGeneralSettingData(filterData));
    } catch (error) {
      console.error(error.message);
    }
  };

  const getLocalIp = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}IpAddress/get`
    );
    const data1 = common.decrypt(data);

    setGetIp(data1);
  };

  useEffect(() => {
    getBlockedIps();
    getSettingData();
    getLocalIp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasMatchingItem = blockedIps?.some(
    (item) => item.ip_address === getIp && item.login_access === "N"
  );
  const blockedType = blockedIps?.find(
    (item) => item.ip_address === getIp && item.login_access === "N"
  );

  const submitData = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    let ipAddress = await axios.get(
      `${process.env.REACT_APP_BASE_URL}IpAddress/get`
    );
    const localIp = common.decrypt(ipAddress.data);

    let ip = localIp;
    let userData = {
      email: email.trim(),
      password: password,
      browserName: browser.name,
      ip: ip,
    };
    let encryptData = common.encrypt(userData);

    let reqData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(encryptData),
    };

    const api = await fetch(
      `${process.env.REACT_APP_BASE_URL}user/login`,
      reqData
    );
    const response = await api.json();

    let data;
    if (response?.data?.data) {
      data = await common.decrypt(response?.data?.data);
    }
    if (response.status === "success") {
      setIsLoading(false);
      localStorage.setItem("user_id", data.id);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_name", data.user_name);
      localStorage.setItem("role", data.role);
      ToastMessage.Success("Successfully logged in...");
      navigate("/dashboard");
      dispatch(addUserData(data));
    }

    if (response.status === "403" && response.message === "User not found.") {
      setErrors({
        email: "User not found. Please check your email and try again.",
        password: "",
      });
    } else if (
      response.status === "403" &&
      response.message === "User account is not active"
    ) {
      setErrors({
        email: "",
        password:
          "Your account is currently inactive. Please contact the administrator for assistance.",
      });
    } else if (
      response.status === "403" &&
      response.message === "you are blocked."
    ) {
      setErrors({
        email: "",
        password: "your account is blocked. contact admin for further details",
      });
    } else if (
      response.status === "403" &&
      response.message ===
        "account is deleted. contact admin for further details."
    ) {
      setErrors({
        email: "",
        password: "account is deleted. contact admin for further details.",
      });
    } else if (
      response.status === "403" &&
      response.message === "Invalid password."
    ) {
      setErrors({
        email: "",
        // password: "Invalid password. Please try again.",
        password: "",
        loginAttepts: `Incorrect Password. you have ${
          totalLoginAttempts?.field_value - response.data.login_attempts
        } attempts remaining `,
      });
      setLoginAttempts(data?.data);
    } else if (
      response.status === "403" &&
      response.message === "Please enter correct credentials...!"
    ) {
      setErrors({
        email: "",
        // password: "Please enter correct credentials...!",
        password:
          "The username or password you entered is incorrect. Please double-check your credentials and try again.",
      });
    } else if (
      response.status === "403" &&
      response.message ===
        "You are blocked by the admin. contact admin for further details."
    ) {
      setErrors({
        email: "",
        password:
          "You are blocked by the admin. contact admin for further details.",
      });
    }

    if (response.status === "fail") {
      navigate("/login");
      ToastMessage.Error(response.message);
    }
    if (response.length < 0) {
      navigate("/login");
      ToastMessage.Success("OOps error login");
    }
    setIsLoading(false);
  };

  const handleRememberMeChange = () => {
    const newValue = !rememberMe;
    setRememberMe(newValue);
    sessionStorage.setItem("rememberedRememberMe", newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitData();

    if (rememberMe) {
      sessionStorage.setItem("rememberedEmail", email);
      sessionStorage.setItem("rememberedPassword", password);
    } else {
      sessionStorage.removeItem("rememberedEmail");
      sessionStorage.removeItem("rememberedPassword");
    }
  };

  return (
    <React.Fragment>
      <Box className="right-side">
        {parseLimitedAccess?.length !== 0 ? (
          hasMatchingLimitedAccess ? (
            <>
              <Box>
                <h5 className="main-title">Welcome !</h5>
                <p className="main-subtitle">{`Sign in to continue to ${siteName}.`}</p>
                <Box
                  sx={{
                    textAlign: "center",
                    display: errors.email ? "block" : "none",
                    color: "red",
                    fontSize: "20px",
                  }}
                >
                  {errors.email}
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    display: errors.password ? "block" : "none",
                    color: "red",
                    fontSize: "20px",
                  }}
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                  role="alert"
                >
                  {errors.password}
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    display: errors?.loginAttepts ? "block" : "none",
                    color: "red",
                    fontSize: "20px",
                  }}
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                  role="alert"
                >
                  {errors?.loginAttepts}
                </Box>
              </Box>

              <Box className="form-container">
                <Form onSubmit={handleSubmit}>
                  <Box className="mb-4">
                    <Label htmlFor="username" className="form-label">
                      Email
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmailBlur}
                    />
                    {validationErrors.email && (
                      <div className="error-text">{validationErrors.email}</div>
                    )}
                  </Box>

                  <Box className="mb-4">
                    <Label className="form-label" htmlFor="password-input">
                      Password
                    </Label>
                    <Box className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder="Enter Password"
                        id="password-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={handlePasswordBlur}
                      />
                      <button
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="btn btn-link position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <RemoveRedEyeIcon sx={{ color: "#BABCC1" }} />
                      </button>
                      {validationErrors.password && (
                        <div className="error-text">
                          {validationErrors.password}
                        </div>
                      )}
                    </Box>
                  </Box>

                  <Box className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={rememberMe}
                      id="auth-remember-me-check"
                      onChange={handleRememberMeChange}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      Remember me
                    </Label>
                    <Box className="float-end">
                      <Link to="/forgot-password" className="forgot-link">
                        Forgot password?
                      </Link>
                    </Box>
                  </Box>

                  <Box className="mt-5">
                    <Button
                      color="primary"
                      className="submit-btn"
                      type="submit"
                    >
                      {isLoading ? (
                        <Spinner size="sm" variant="light" />
                      ) : (
                        "Sign In"
                      )}
                    </Button>
                  </Box>
                </Form>
              </Box>
            </>
          ) : (
            <>
              <Box className="text-center mb-4">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ color: "red" }}>IP BLOCKED</h1>
                  <p style={{ color: "red" }}>
                    {blockedType && blockedType?.blocked_type === "Login"
                      ? "This IP is blocked due to too many login attempts!"
                      : "You cannot access due to limited Ip access!"}
                    <br></br>
                    Please contact administrator for further assistance
                  </p>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <img src={ImgBlockedIp} alt="IpBlocked" />
                </Box>
              </Box>
            </>
          )
        ) : getIp !== undefined &&
          (settingBlockedIp?.field_value === getIp ||
            hasMatchingItem === true ||
            loginAttempts?.login_attempts ===
              +totalLoginAttempts?.field_value) ? (
          <>
            <Box className="text-center mb-4">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1 style={{ color: "red" }}>IP BLOCKED</h1>
                <p style={{ color: "red" }}>
                  {blockedType && blockedType?.blocked_type === "Login"
                    ? "This IP is blocked due to too many login attempts!"
                    : "You cannot access due to limited Ip access!"}
                  <br></br>
                  Please contact administrator for further assistance
                </p>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <img src={ImgBlockedIp} alt="IpBlocked" />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <h5 className="main-title">Welcome !</h5>
              <p className="main-subtitle">{`Sign in to continue to ${siteName}.`}</p>
              <Box
                sx={{
                  display: errors.email ? "block" : "none",
                }}
                className="alert alert-borderless alert-danger text-center username-login-alert"
                role="alert"
              >
                {errors.email}
              </Box>
              <Box
                sx={{
                  display: errors.password ? "block" : "none",
                }}
                className="alert alert-borderless alert-danger text-center username-login-alert"
                role="alert"
              >
                {errors.password}
              </Box>
              <Box
                sx={{
                  display: errors?.loginAttepts ? "block" : "none",
                }}
                className="alert alert-borderless alert-danger text-center username-login-alert"
                role="alert"
              >
                {errors?.loginAttepts}
              </Box>
            </Box>

            <Box className="form-container">
              <Form onSubmit={handleSubmit}>
                <Box className="mb-4">
                  <Label htmlFor="username" className="form-label">
                    Email
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleEmailBlur}
                  />
                  {validationErrors.email && (
                    <div className="error-text">{validationErrors.email}</div>
                  )}
                </Box>

                <Box className="mb-3">
                  <Label className="form-label" htmlFor="password-input">
                    Password
                  </Label>
                  <Box className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      type={passwordShow ? "text" : "password"}
                      className="form-control pe-5 password-input"
                      placeholder="Enter Password"
                      id="password-input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={handlePasswordBlur}
                    />
                    <button
                      onClick={() => setPasswordShow(!passwordShow)}
                      className="btn btn-link position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                      type="button"
                      id="password-addon"
                    >
                      {passwordShow ? <VisibilityOff /> : <Visibility />}
                    </button>
                    {validationErrors.password && (
                      <div className="error-text">
                        {validationErrors.password}
                      </div>
                    )}
                  </Box>
                </Box>

                <Box className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    value={rememberMe}
                    checked={rememberMe}
                    id="auth-remember-check"
                    onChange={handleRememberMeChange}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="auth-remember-check"
                  >
                    Remember me
                  </Label>
                  <Box className="float-end">
                    <Link to="/forgot-password" className="forgot-link">
                      Forgot password?
                    </Link>
                  </Box>
                </Box>

                <Box className="mt-5">
                  <Button
                    color="primary"
                    className="submit-btn btn-dark"
                    type="submit"
                    disabled={isLoading}
                    // onClick={submitData}
                  >
                    {isLoading ? (
                      <Spinner size="sm" variant="light" />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                </Box>
              </Form>
            </Box>

            <Box className="login-footer">
              {/* <Box>
                <Link to="#">
                  <HelpRoundedIcon /> Help Center
                </Link>
                <Link to="#">
                  <i className="bx bxs-credit-card"></i> Control Base Demo
                </Link>
              </Box> */}
              <Box sx={{ textAlign: { lg: "start" } }}>
                <Link to="#">
                  <CopyrightRoundedIcon /> 2024 Netclues.
                </Link>
                <Link to="#" className="terms-line">
                  Terms & Conditions
                </Link>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default CoverSignIn;
