import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import FormatDate from "../../../../Components/Common/formatDate";
import ExtractDomain from "../../../../Components/Common/extractDomain";
import LOGO from "../../../../assets/images/controlbase-logo.png";
import { auto } from "@popperjs/core";
import { decodeAsciiEntities } from "../../../../Components/Common/common";
import { Font } from '@react-pdf/renderer';
// Define styles
const styles = StyleSheet.create({  
  body: {
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  page: {
    fontFamily: "Arial, Helvetica, sans-serif",
    color: "#1a2232",
    paddingTop: 30,
    paddingbottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30,
    backgroundColor: "#f5f5f5",
    padding: 10,
  },
  logoContainer: {
    width: 180,
    height: 60,
    maxHeight: 80,
    marginLeft: 0,
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  detailsContainer: {
    borderLeft: "1px solid #ced4da",
    marginLeft: 20,
    paddingLeft: 20,
    textAlign: "right",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  statusBadge: {
    backgroundColor: "#49af36",
    borderRadius: 100,
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: "#fff",
    fontWeight: 500,
    marginRight: 10,
  },
  createDate: {
    fontSize: 13,
    fontWeight: 500,
    paddingTop: 3,
  },
  summaryContainer: {
    textAlign: "center",
    marginBottom: 30,
  },
  summaryTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 3,
  },
  summaryText: {
    fontSize: 12,
    margin: 0,
    fontWeight: 400,
  },
  scoreContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
    gap: 20,
    marginBottom: 20,
  },
  gradeBox: {
    width: "48%",
    borderRadius: 12,
    overflow: "hidden",
    height: "100%",
    color: "#fff",
    backgroundColor: "#53a553",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    padding: 0,
  },
  scoreBox: {
    width: "48%",
  },
  scoreItem: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px 12px",
    fontSize: "14px",
    borderRadius: "8px",
  },
  scoreItemTitle: {
    marginBottom: 3,
    fontSize: 11,
    fontWeight: 400,
  },
  scoreTitle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 5,
  },
  scoreGrade: {
    fontSize: 24,
    fontWeight: 600,
    margin: 0,
    textAlign: "center",
  },
  securityImprovements: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 20,
  },
  improvementsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  improvementItem: {
    width: "47%",
    marginBottom: 10,
  },
  securityTitle: {
    lineHeight: 1.5,
    fontWeight: "bold",
    fontSize: 13,
    margin: 0,
    borderBottom: "1px solid #e4e8eb",
  },
  securityDetail: {
    fontSize: 11,
    marginTop: 10,
  },
  badgeDetail: {
    fontSize: 11,
    padding: 13,
    borderRadius: 6,
    backgroundColor: "#f5f5f5",
  },
  securityContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  secheight: {
    height: "100%",
    width: "19%",
  },
  securityCard: {
    borderRadius: 10,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    // height: "100%",
  },
  topSide: {
    backgroundColor: "#e37b00",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
    fontWeight: 700,
  },
  testTitle: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontSize: 13,
    fontWeight: 'bold',
    color: "#fff",
    marginBottom: 5,
  },
  testSubtitle: {
    fontSize: 10,
    fontWeight: 400,
    color: "#fff",
    margin: 0,
    minHeight: 30,
  },
  securityBottom: {
    backgroundColor: "#fd8900",
    color: "#fff",
    margin: 0,
    fontSize: 11,
    textAlign: "center",
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 8,
    paddingRight: 8,
    // height: "100%",
  },
  versionStyle: {
    backgroundColor: "#49af36",
    borderRadius: 100,
    fontSize: 11,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: "#fff",
    fontWeight: 500,
    margin: 1,
  },
  topGreen: {
    backgroundColor: "#53a553",
  },
  bottomGreen: {
    backgroundColor: "#5cb85c",
  },
  topOrange: {
    backgroundColor: "#e37b00",
  },
  bottomOrange: {
    backgroundColor: "#fd8900",
  },
  topRed: {
    backgroundColor: "#d84a2e",
  },
  bottomRed: {
    backgroundColor: "#ed5c40",
  },
  topYellow: {
    backgroundColor: "#eead00",
  },
  bottomYellow: {
    backgroundColor: "#ffbc0a",
  },

  page: {
    fontFamily: 'Helvetica',
    padding: 10,
  },
  cardTitle: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  labelDetail: {
    marginBottom: 15,
    padding: 10,
    borderRadius: 4,
  },
  blueLabel: {
    backgroundColor: '#337ab7',
    color: '#FFFFFF',
  },
  orangeLabel: {
    backgroundColor: '#FFA726',
    color: '#FFFFFF',
  },
  greenLabel: {
    backgroundColor: '#66BB6A',
    color: '#FFFFFF',
  },
  lightOrangeLabel: {
    backgroundColor: '#fcf8e3',
  },
  lightBlueLabel: {
    backgroundColor: '#d9edf7',
  },
  lightGreenLabel: {
    backgroundColor: '#dff0d8',
  },
  
  descriptionText: {
    fontSize: 11,
    marginBottom: 5,
  },
  labelText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  headerLabel: {
    fontSize: 12,
    maxHeight: '100px',
    overflow: 'auto',
    marginBottom: 10,
  },
  directivesTable: {
    marginBottom: 40,
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableRow: {
    fontSize: 10,
    paddingVertical: 2,
  },
  configurationBox: {
    backgroundColor: '#dff0d8',
    color: '#1a2232',
    fontSize: 12,
    borderRadius: 6,
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  configurationBoxTitle: {
    width: "75%",
    paddingRight: "15px",
  },
  configurationBoxBtn: {
    backgroundColor: '#5cb85c',
    color: '#FFFFFF',
    borderRadius: 50,
    padding: "5px 10px",
    display: "inline-block",
    width: "25%",
    textAlign: "center",
  },
});

// Component to render PDF content
const PDFDocument = ({
  onlineAssetData = {},
  immuniWeb = {},
  userName = "",
  testCount = "",
}) => {
  const summaryData = immuniWeb?.summary && JSON.parse(immuniWeb?.summary);

  const webServerSecurityTest =
    immuniWeb?.web_server_security_test &&
    JSON.parse(immuniWeb?.web_server_security_test);

  const webSoftwareSecurityTest = immuniWeb?.web_software_security_test
    ? JSON.parse(immuniWeb.web_software_security_test)
    : null;

  const gdprComplianceTest = immuniWeb?.gdpr_compliance
    ? JSON.parse(immuniWeb.gdpr_compliance)
    : null;

  const pcidssComplianceTest = immuniWeb?.pci_dss_compliance
    ? JSON.parse(immuniWeb.pci_dss_compliance)
    : null;

  const contentSecurityPolicyTest = immuniWeb?.content_security_policy_test
    ? JSON.parse(immuniWeb.content_security_policy_test)
    : null;

  const cookiesPrivacySecurityTest = immuniWeb?.cookies_privacy_security_test
    ? JSON.parse(immuniWeb.cookies_privacy_security_test)
    : null;

  const httpHeaderSecurityData = immuniWeb?.http_header_security_data
    ? JSON.parse(immuniWeb.http_header_security_data)
    : null;

  // const renderListItem = (number, label, linkText) => (
  //   <ListItem
  //     sx={{
  //       "&.MuiListItem-root": {
  //         padding: 0,
  //       },
  //     }}
  //   >
  //     <Box
  //       sx={{
  //         background: "#CED4DA",
  //         color: "#1A2232",
  //         borderRadius: "15px",
  //         padding: "4px 14px",
  //         fontSize: "15px",
  //         marginRight: "5px",
  //       }}
  //     >
  //       {number}.
  //     </Box>
  //     <Chip
  //       label={label}
  //       variant="outlined"
  //       component="a"
  //       to={linkText}
  //       clickable
  //       sx={{
  //         "&.MuiChip-root": {
  //           background: "transparent",
  //           borderColor: "#CED4DA",
  //         },
  //       }}
  //     />
  //   </ListItem>
  // );

  const getStyles = (className) => {
    switch (className) {
      case "green":
        return {
          topStyle: styles.topGreen,
          bottomStyle: styles.bottomGreen,
        };
      case "orange":
        return {
          topStyle: styles.topOrange,
          bottomStyle: styles.bottomOrange,
        };
      case "red":
        return {
          topStyle: styles.topRed,
          bottomStyle: styles.bottomRed,
        };
      case "yellow":
        return {
          topStyle: styles.topYellow,
          bottomStyle: styles.bottomYellow,
        };
      default:
        return {
          topStyle: styles.topGreen,
          bottomStyle: styles.bottomGreen,
        };
    }
  };

  const GDPRData = [
    {
      id: 1,
      title: "PRIVACY POLICY",
      description: "Privacy Policy was found on the website.",
      label: "Good configuration",
      status: "green-label",
      tooltip:
        "Article 13 of GDPR requires data controller to provide a conspicuously visible notice to data subjects when collecting their personal data including but not limited to data collected by web applications.",
    },
    {
      id: 2,
      title: "WEBSITE SECURITY",
      description:
        "No publicly known vulnerabilities were found in the website CMS or its components.",
      label: "Good configuration",
      status: "green-label",
      tooltip:
        "Article 5(1)(f), Article 24(1) and Article 32 of GDPR require implementation, testing and maintenance of adequate security controls to protect personal data. EDPB guidelines provide further technical details and examples including, among other things, maintenance of up2date web application software and regular website security testing.",
    },
    {
      id: 3,
      title: "TLS ENCRYPTION",
      description: "HTTPS encryption is present on the web server.",
      label: "Good configuration",
      status: "green-label",
      tooltip:
        "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to web applications when personal data is being sent or retrieved via a browser or API.",
    },
    {
      id: 4,
      title: "COOKIE PROTECTION",
      description:
        "No cookies with personal or tracking information seem to be sent.",
      label: "Information",
      status: "blue-label",
      tooltip:
        "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to cookies when they contain personal data or identifiers attributable to data subjects (see GDPR Recital 30).",
    },
    {
      id: 5,
      title: "COOKIE DISCLAIMER",
      description:
        "No third-party cookies or cookies with tracking information seem to be sent.",
      label: "Information",
      status: "blue-label",
      tooltip:
        "In addition to Article 13 of GDPR, the EU ePrivacy Directive requires website operators to obtain an informed data subject’s consent prior to setting any cookies except strictly necessary cookies.",
    },
  ];

  const updatedGDPRData = GDPRData.map((data) => {
    const testItem = gdprComplianceTest?.find(
      (item) => item.title === data.title
    );
    const status = testItem && testItem.check ? "statusGreen" : "statusBlue";
    const label =
      testItem && testItem.check ? "Good configuration" : "Information";

    return {
      ...data,
      label,
      status,
      check: testItem ? testItem.check : null, // Add check value to data
    };
  });

  const renderGDPR = updatedGDPRData?.map((data) => (
   
    <View key={data.id} style={{ marginBottom: 10 }}>
      <Text style={styles.securityTitle}>{data.title}</Text>
      <Text style={styles.securityDetail}>{" (" + data.tooltip + ")"}</Text>   
      <View style={[styles.configurationBox, data.status == "statusGreen" ? styles.lightGreenLabel : styles.lightBlueLabel]}>
        <Text style={[styles.configurationBoxTitle, styles[data.status]]}>
          {data.description}</Text>
        {data.check !== null && <Text style={[styles.configurationBoxBtn, data.status == "statusGreen" ? styles.greenLabel : styles.blueLabel]}>{data.label}</Text>}
      </View>   
    </View>
  ));

  const updatedPCIDSSData = pcidssComplianceTest?.map((testItem, index) => {
    return {
      id: index + 1,
      title: testItem.title,
      description: testItem.msg,
      label:
        testItem.lvl === 1
          ? "Good configuration"
          : "Misconfiguration or weakness",
      status: testItem.lvl === 1 ? "green-label" : "orange-label",
      tooltip: testItem?.tooltip,
    };
  });

  const PolicyDirectivesRows =
    contentSecurityPolicyTest?.content_security_policy?.directives?.map(
      (directive) => ({
        name: directive.name,
        description: directive?.description?.replace(/\s*\[\d+\]$/, ""),
        alerts: "No problems found",
      })
    ) || [];

  const PolicyDirectivesReportsRows =
    contentSecurityPolicyTest?.content_security_policy_report_only?.directives?.map(
      (directive) => ({
        name: directive.name,
        description: directive?.description?.replace(/\s*\[\d+\]$/, ""),
        alerts: "No problems found",
      })
    ) || [];

  return (
    <Document>
      <Page size="A4" style={{padding:"20px",}} wrap={false} >
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            {onlineAssetData.website_logo ? (
              <Image
                src={`${process.env.REACT_APP_BASE_URL}${onlineAssetData.website_logo}`}
                style={styles.logo}
              />
            ) : (
              <Image src={LOGO} style={styles.logo} />
            )}
          </View>
          <View style={styles.detailsContainer}>
            <Text style={styles.title}>
              {onlineAssetData.website_full_name}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "right",
                justifyContent: "end",
                marginBottom: 5,
                textAlign: "right",
              }}
            >
              <Text style={styles.statusBadge}>{onlineAssetData.status}</Text>
              <Text style={styles.createDate}>
                {FormatDate(onlineAssetData.createdAt)}
              </Text>
            </View>
          </View>
        </View>

        {/* Summary */}
        <View style={styles.summaryContainer}>
          <Text style={styles.summaryTitle}>Summary of {ExtractDomain(immuniWeb?.url)} Website Security Test</Text>
          <Text style={styles.summaryText}>{ExtractDomain(immuniWeb?.url)} was tested {testCount} times during the last 12 months.</Text>
        </View>

        {/* Score */}
        <View style={styles.scoreContainer}>
          <View style={styles.gradeBox}>
            <Text
              style={{
                fontSize: 100,
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            >
              {summaryData?.grade}
            </Text>
            <View style={{ textAlign: "center" }}>
              <Text style={styles.scoreTitle}>Your Final Score</Text>
              <Text style={styles.scoreGrade}>Grade</Text>
            </View>
          </View>
          <View style={styles.scoreBox}>
            <View style={styles.scoreItem}>
              <Text style={styles.scoreItemTitle}>Security Tested On</Text>
              <Text style={{ fontSize: 14, fontWeight: 600 }}>
                {new Date(immuniWeb?.updatedAt).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </Text>
            </View>
            <View style={{ padding: 5 }}></View>
            <View style={styles.scoreItem}>
              <Text style={styles.scoreItemTitle}>Tested By</Text>
              <Text style={{ fontSize: 14, fontWeight: 600 }}>{userName}</Text>
            </View>
          </View>
        </View>

        <View style={styles.securityContainer}>
          <View style={styles.secheight}>
            <View style={styles.securityCard}>
              <View
                style={[
                  styles.topSide,
                  getStyles(summaryData?.malware?.class).topStyle,
                ]}
              >
                <Text style={styles.testTitle}>MALWARE</Text>
                <Text style={styles.testSubtitle}>Software Security Test</Text>
              </View>
              <Text
                style={[
                  styles.securityBottom,
                  getStyles(summaryData?.malware?.class).bottomStyle,
                ]}
              >
                {summaryData?.malware?.description}
              </Text>
            </View>
          </View>
          <View style={styles.secheight}>
            <View style={styles.securityCard}>
              <View
                style={[
                  styles.topSide,
                  getStyles(summaryData?.eu_gdpr?.class).topStyle,
                ]}
              >
                <Text style={styles.testTitle}>EU GDPR</Text>
                <Text style={styles.testSubtitle}>Compliance Test</Text>
              </View>
              <Text
                style={[
                  styles.securityBottom,
                  getStyles(summaryData?.eu_gdpr?.class).bottomStyle,
                ]}
              >
                {summaryData?.eu_gdpr?.description}
              </Text>
            </View>
          </View>
          <View style={styles.secheight}>
            <View style={styles.securityCard}>
              <View
                style={[
                  styles.topSide,
                  getStyles(summaryData?.pci_dss?.class).topStyle,
                ]}
              >
                <Text style={styles.testTitle}>PCI DSS</Text>
                <Text style={styles.testSubtitle}>Software Security Test</Text>
              </View>
              <Text
                style={[
                  styles.securityBottom,
                  getStyles(summaryData?.pci_dss?.class).bottomStyle,
                ]}
              >
                {summaryData?.pci_dss?.description}
              </Text>
            </View>
          </View>
          <View style={styles.secheight}>
            <View style={styles.securityCard}>
              <View
                style={[
                  styles.topSide,
                  getStyles(summaryData?.security?.class).topStyle,
                ]}
              >
                <Text style={styles.testTitle}>SECURITY</Text>
                <Text style={styles.testSubtitle}>Software Security Test</Text>
              </View>
              <Text
                style={[
                  styles.securityBottom,
                  getStyles(summaryData?.security?.class).bottomStyle,
                ]}
              >
                {summaryData?.security?.description}
              </Text>
            </View>
          </View>

          <View style={styles.secheight}>
            <View style={styles.securityCard}>
              <View
                style={[
                  styles.topSide,
                  getStyles(summaryData?.http?.class).topStyle,
                ]}
              >
                <Text style={styles.testTitle}>HTTP</Text>
                <Text style={styles.testSubtitle}>Header Security Test</Text>
              </View>
              <Text
                style={[
                  styles.securityBottom,
                  getStyles(summaryData?.http?.class).bottomStyle,
                ]}
              >
                {summaryData?.http?.description}
              </Text>
            </View>
          </View>
        </View>

        {/* Security Issues */}
        <Text style={styles.improvementsTitle}>Web Server Security Test</Text>
        <View style={styles.securityImprovements}>
          {webServerSecurityTest &&
            webServerSecurityTest.http_response !== null && (
              <View style={styles.improvementItem}>
                <Text style={styles.securityTitle}>HTTP RESPONSE</Text>
                <Text style={styles.securityDetail}>
                  {" "}
                  {webServerSecurityTest?.http_response}
                </Text>
              </View>
            )}

          {webServerSecurityTest?.http_versions?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>HTTP VERSIONS</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                {webServerSecurityTest?.http_versions?.map((version, index) => (
                  <Text key={index} style={styles.versionStyle}>
                    {version}
                  </Text>
                ))}
              </View>
            </View>
          )}

          {/* {webServerSecurityTest?.npn?.length > 0 && (
              <View style={styles.improvementItem}>
                <Text style={styles.securityTitle}>
                  NPN

                </Text>
                <Text style={styles.securityDetail}>
                  {webServerSecurityTest?.npn?.map((version, index) => (
                    <Chip key={index} label={version} />
                  ))}
                </Text>
              </View>
            )} */}

          {webServerSecurityTest?.alpn && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}> ALPN </Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest?.alpn}
              </Text>
            </View>
          )}

          {webServerSecurityTest?.content_encoding?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>CONTENT ENCODING</Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest &&
                webServerSecurityTest?.content_encoding !== undefined
                  ? `${webServerSecurityTest?.content_encoding}`
                  : "-"}
              </Text>
            </View>
          )}

          {webServerSecurityTest &&
            webServerSecurityTest?.server_signature !== undefined &&
            webServerSecurityTest?.server_signature.length > 0 && (
              <View style={styles.improvementItem}>
                <Text style={styles.securityTitle}>SERVER SIGNATURE</Text>
                <Text style={styles.securityDetail}>
                  {webServerSecurityTest?.server_signature}
                </Text>
              </View>
            )}

          {/* {webServerSecurityTest?.waf?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>
                WAF
                <Tooltip
                  title={"Web Application Firewall"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest && webServerSecurityTest?.waf !== null
                  ? `${webServerSecurityTest?.waf}`
                  : "-"}
              </Text>
            </View>
          )} */}

          {webServerSecurityTest?.waf?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>WAF</Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest && webServerSecurityTest?.waf !== null
                  ? `${webServerSecurityTest?.waf}`
                  : "-"}
              </Text>
            </View>
          )}

          {webServerSecurityTest?.location?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>LOCATION</Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest &&
                webServerSecurityTest?.location !== null
                  ? `${webServerSecurityTest?.location}`
                  : "-"}
              </Text>
            </View>
          )}

          {webServerSecurityTest?.http_methods?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>HTTP METHODS ENABLED</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                {webServerSecurityTest?.http_methods?.map((method, index) => (
                  <Text key={index} style={styles.versionStyle}>
                    {method}
                  </Text>
                ))}
              </View>
            </View>
          )}
          {webServerSecurityTest?.http_redirects?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>HTTP REDIRECTS</Text>
              <View
                style={{
                  // flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                {webServerSecurityTest?.http_redirects?.map((method, index) => (
                  <Text
                    key={index}
                    style={{
                      flexDirection: "row",
                      // alignItems: "center",
                      // marginBottom: 10,
                      marginTop: 3,
                      fontSize: "12px",
                    }}
                  >
                    {method}
                  </Text>
                ))}
              </View>
            </View>
          )}

          {/* {webServerSecurityTest?.http_redirects?.length > 0 && (
            <View style={styles.improvementItem}>
              <Text style={styles.securityTitle}>
                HTTP REDIRECTS{" "}
                <Tooltip
                  title={"The Original URL redirects to these web resources"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </Text>
              <Text style={styles.securityDetail}>
                {webServerSecurityTest?.http_redirects?.map(
                  (redirect, index) => (
                    <ListItem key={index}>
                      {renderListItem(index + 1, redirect, redirect)}
                    </ListItem>
                  )
                )}
              </Text>
            </View>
          )} */}
        </View>

        {/* Web Software Security Test */}
        <Text style={styles.improvementsTitle}>Web Software Security Test</Text>
        <View style={styles.card}>
          <Text style={styles.securityTitle}>Fingerprinted CMS & Vulnerabilities</Text>
            <Text style={styles.securityDetail}>
              {
                " (The name of CMS was fingerprinted using non-intrusive techniques and therefore are not guaranteed to be accurate.)"
              }
            </Text>
          

          {webSoftwareSecurityTest?.fingerprintedCms ? (
            <View 
              style={{
                marginBottom: 25,
              }}
            >
              <View 
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <View style={styles.chipBox}>
                  <Text style={styles.versionStyle}>
                    {webSoftwareSecurityTest?.fingerprintedCms}
                  </Text>
                </View>
              </View>
              <Text style={styles.badgeDetail}>
                {webSoftwareSecurityTest?.cmsVersionInfo !== undefined
                  ? `${webSoftwareSecurityTest?.cmsVersionInfo}`
                  : "-"}
              </Text>
            </View>
          ) : (
            <Text>Data not found</Text>
          )}

          <Text style={styles.securityTitle}>Fingerprinted CMS Components & Vulnerabilities</Text>
          <Text style={styles.securityDetail}>
              {
                " (The name of CMS was fingerprinted using non-intrusive techniques and therefore are not guaranteed to be accurate.)"
              }
          </Text>
          

          {webSoftwareSecurityTest?.fingerprintedCmsComponents?.length > 0 ? (
            webSoftwareSecurityTest?.fingerprintedCmsComponents?.map(
              (component, index) => (
                <View key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 10,
                      backgroundColor: "#fd8900",
                      borderRadius: "30px",
                      color: "#fff",
                      overflow: "hidden",
                      maxWidth: "40%",
                      fontSize: "12px",
                    }}
                  >
                    <Text
                      style={{
                        width: "50%",
                        padding: "10px 12px",
                        fontSize: "12px",
                      }}
                    >{component.componentName}</Text>
                    <Text
                      style={{
                        width: "50%",
                        padding: "10px 12px",
                        fontSize: "12px",
                        backgroundColor: "#e37b00",
                        height: "100%",
                      }}
                    >{component.componentVersion}</Text>
                  </View>
                  <Text style={styles.badgeDetail}>
                    {component.componentStatus}{" "}
                    {component.componentVulnerabilities}.{" "}
                    {component.componentUpdateInfo}
                    <Text style={{ fontWeight: "bold" }}>
                      {component.componentNewVersion}
                    </Text>
                    .
                  </Text>
                </View>
              )
            )
          ) : (
            <Text>Data not found</Text>
          )}
        </View>

        {/* GDPR Compliance Test */}
        <View
          style={{
            margin: 12,
          }}
        ></View>

        <Text style={styles.improvementsTitle}>GDPR Compliance Test</Text>

        <View style={styles.card}>
          <Text style={styles.securityDetail}>
            If the website processes or stores personal data of the EU
            residents, the following requirements of EU GDPR may apply:
          </Text>
          <View
          style={{
            margin: 10,
          }}
          ></View>
          <View>
            {gdprComplianceTest && gdprComplianceTest.length > 0 ? (
              renderGDPR
            ) : (
              <Text>Data not found</Text>
            )}
          </View>
        </View>

        {/* PCI DSS Compliance Test */}
        <View
          style={{
            margin: 12,
          }}
          ></View>
        <Text style={styles.improvementsTitle}>PCI DSS Compliance Test</Text>
        <View>
          <Text style={styles.securityDetail}>
            If the website falls into a CDE (Cardholder Data Environment) scope,
            the following Requirements of PCI DSS may apply:
          </Text>
          <View
          style={{
            margin: 10,
          }}
          ></View>

          {updatedPCIDSSData && updatedPCIDSSData.length > 0 ? (
            updatedPCIDSSData.map((data) => (
              <View key={data.id} style={styles.card}>
                <Text style={styles.securityTitle}>{data.title}</Text>
                {data.tooltip && (
                  <Text style={styles.securityDetail}>{data.tooltip}</Text>
                )}
                
                <View
                  style={[
                    styles.configurationBox,
                    data.status === "green-label"
                      ? styles.lightGreenLabel
                      : styles.lightOrangeLabel,
                  ]}
                >
                  <Text style={styles.configurationBoxTitle}>{data.description}</Text>
                  <Text style={[styles.configurationBoxBtn, data.status == "green-label" ? styles.greenLabel : styles.orangeLabel]}>{data.label}</Text>
                </View>
                
              </View>
            ))
          ) : (
            <View>
              <Text>No Data Found</Text>
            </View>
          )}
        </View>

        {/* Content Security Policy Test */}
        <View
          style={{
            margin: 12,
          }}
          ></View>
        <Text style={styles.improvementsTitle}>
          Content Security Policy Test
        </Text>
        <View style={styles.section}>
          <Text style={styles.securityTitle}>CONTENT-SECURITY-POLICY</Text>
            {contentSecurityPolicyTest?.content_security_policy
              ?.description && (
              <Text style={styles.securityDetail}>
                {" "}
                -{" "}
                {
                  contentSecurityPolicyTest?.content_security_policy
                    ?.description
                }
              </Text>
            )}
          

          {contentSecurityPolicyTest?.content_security_policy?.highlight?.map(
            (item, index) => (
              <View key={index} style={[styles.labelDetail, styles.lightGreenLabel]}>
                <Text
                  style={{
                    fontSize: "11px",
                  }}
                >{item?.replace(/\s*\[\d+\]$/, "")}</Text>
              </View>
            )
          )}

          {contentSecurityPolicyTest?.content_security_policy?.raw && (
            <View style={styles.section}>
              <Text style={styles.labelText}>Raw HTTP Header</Text>
              <Text style={styles.headerLabel}>
                {decodeAsciiEntities(
                  contentSecurityPolicyTest.content_security_policy?.raw
                )}
              </Text>
            </View>
          )}

          {PolicyDirectivesRows.length > 0 && (
            <View style={styles.table}>
              <View style={[styles.tableRow, { fontWeight: "bold" }]}>
                <Text style={styles.tableCell}>Name</Text>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>Alerts</Text>
              </View>
              {PolicyDirectivesRows.map((row) => (
                <View style={styles.tableRow} key={row.name}>
                  <Text style={styles.tableCell}>{row.name}</Text>
                  <Text style={styles.tableCell}>{row.description}</Text>
                  <Text style={styles.tableCell}>{row.alerts}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
        <View style={styles.section}>
          <Text style={styles.securityTitle}>CONTENT-SECURITY-POLICY-REPORT-ONLY</Text>
          {contentSecurityPolicyTest?.content_security_policy_report_only?.highlight?.map(
            (item, index) => (
              <View key={index} style={[styles.labelDetail, styles.lightBlueLabel]}>
                <Text
                  style={{
                    fontSize: "11px",
                  }}
                >{item?.replace(/\s*\[\d+\]$/, "")}</Text>
              </View>
            )
          )}

          {contentSecurityPolicyTest?.content_security_policy_report_only
            ?.raw && (
            <View style={styles.section}>
              <Text style={styles.labelText}>Raw HTTP Header</Text>
              <Text style={{
                    fontSize: "11px",
                  }}>
                {decodeAsciiEntities(
                  contentSecurityPolicyTest.content_security_policy_report_only
                    ?.raw
                )}
              </Text>
            </View>
          )}

          {PolicyDirectivesReportsRows.length > 0 && (
            <View style={styles.table}>
              <View style={[styles.tableRow, { fontWeight: "bold" }]}>
                <Text style={styles.tableCell}>Name</Text>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>Alerts</Text>
              </View>
              {PolicyDirectivesReportsRows.map((row) => (
                <View style={styles.tableRow} key={row.name}>
                  <Text style={styles.tableCell}>{row.name}</Text>
                  <Text style={styles.tableCell}>{row.description}</Text>
                  <Text style={styles.tableCell}>{row.alerts}</Text>
                </View>
              ))}
            </View>
          )}
        </View>

        <View
          style={{
            margin: 12,
          }}
        ></View>
        {/* Cookies Privacy and Security Analysis */}
        <Text style={styles.improvementsTitle}>
          Cookies Privacy and Security Analysis
        </Text>
        <View style={styles.card}>
          <Text style={styles.securityTitle}>
            Cookies Privacy and Security Analysis
          </Text>

          {cookiesPrivacySecurityTest?.cookieInfo?.highlight && (
            <View style={styles.configurationBox}>
              <Text style={styles.configurationBoxTitle}>
                {cookiesPrivacySecurityTest?.cookieInfo?.highlight?.replace(
                  /\s*\[\d+\]$/,
                  ""
                )}
              </Text>
              <Text style={styles.configurationBoxBtn}>Good Configuration</Text>
            </View>
          )}

          {cookiesPrivacySecurityTest?.bodyInfo?.map((policy, index) => (
            <View key={index}>
              <Text style={styles.securityTitle}>Cookies {policy?.name}</Text>

              {policy?.highlight?.map((item, idx) => (
                <View key={idx} style={styles.configurationBox}>
                  <Text style={styles.configurationBoxTitle}>
                    {item?.replace(/\s*\[\d+\]$/, "")}
                  </Text>
                  <Text style={styles.configurationBoxBtn}>Good Configuration</Text>
                </View>
              ))}

              <View>
                <Text style={styles.labelText}>Raw HTTP Header</Text>
                <Text style={styles.headerLabel}>
                  {policy.raw !== undefined
                    ? decodeAsciiEntities(policy?.raw)
                    : "-"}
                </Text>
              </View>

              <View style={styles.tableContainer}>
                <Text style={styles.labelText}>Directives</Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.tableCellHeader,
                        { width: "120px" },
                      ]}
                    >
                      Name
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.tableCellHeader,
                        { width: "350px" },
                      ]}
                    >
                      Description
                    </Text>
                    <Text style={styles.tableCellHeader}>Alerts</Text>
                  </View>
                  {policy.directives?.map((directive, idx) => (
                    <View key={idx} style={styles.tableRow}>
                      <Text style={[styles.tableCell, { width: "120px" }]}>
                        {directive?.name}
                      </Text>
                      <Text style={[styles.tableCell, { width: "350px" }]}>
                        {directive?.description?.replace(/\s*\[\d+\]$/, "")}
                      </Text>
                      <Text style={styles.tableCell}>No problems found</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          ))}
        </View>

        {/* HTTP Headers Security */}
        <View
          style={{
            margin: 12,
          }}
        ></View>
        <Text style={styles.improvementsTitle}>HTTP Headers Security</Text>

        {httpHeaderSecurityData?.length > 0 && (
          <View style={[styles.labelDetail, styles.orangeLabel]}>
            <Text style={styles.descriptionText}>
              {httpHeaderSecurityData?.headerInfo?.highlight
                ? `${httpHeaderSecurityData.headerInfo.highlight.replace(
                    /\s*\[\d+\]$/,
                    ""
                  )}`
                : "-"}
            </Text>
            <Text>Misconfiguration or weakness</Text>
          </View>
        )}
        <View>
          {httpHeaderSecurityData?.bodyInfo?.map((item, index) => {
            if (item.name === "MISSING REQUIRED HTTP HEADERS") {
              return (
                <View key={index}>
                  <Text style={styles.securityTitle}>
                    MISSING REQUIRED HTTP HEADERS
                  </Text>
                  <Text style={styles.securityDetail}>
                    If missing, these HTTP security headers may weaken your
                    website and web server security.
                  </Text>
                  {/* <View style={[styles.labelDetail, styles.greenLabel]}>
                    {item.value?.map((header, idx) => (
                      <View key={idx}>
                        <Text>{header.name}</Text>
                      </View>
                    ))}
                  </View> */}

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                      {item.value?.map((header, idx) => (
                      <Text key={idx} style={[styles.versionStyle, styles.greenLabel]}>
                        {header.name}
                      </Text>
                      ))}
                  </View>  

                </View>                
              );
            }
            return null;
          })}

              <View
                style={{
                  margin: 5,
                }}
              ></View>
          {httpHeaderSecurityData?.bodyInfo?.map((item, index) => {
            if (item.name === "MISSING OPTIONAL HTTP HEADERS") {
              return (
                <View key={index}>
                  <Text style={styles.securityTitle}>
                    MISSING OPTIONAL HTTP HEADERS
                  </Text>
                  <Text style={styles.securityDetail}>
                    Insignificant or otherwise non-obligatory HTTP
                    security-related headers.
                  </Text>
                    <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                      {item.value?.map((header, idx) => (
                      // <View style={[styles.labelDetail, styles.blueLabel]}>
                      //     <View key={idx}>
                      //       <Text>{header.name}</Text>
                      //     </View>
                      // </View>
                      <Text key={idx} style={[styles.versionStyle, styles.blueLabel]}>
                        {header.name}
                      </Text>
                      ))}
                  </View>
                </View>
              );
            }
            return null;
          })}

              <View
                style={{
                  margin: 5,
                }}
              ></View>
          {httpHeaderSecurityData?.bodyInfo?.map((item, index) => {
            if (item.name === "MISSING OTHER HTTP HEADERS") {
              return (
                <View key={index}>
                  <Text style={styles.labelText}>
                    MISSING OTHER HTTP HEADERS
                  </Text>
                  <View style={[styles.labelDetail, styles.blueLabel]}>
                    {item.value?.map((header, idx) => (
                      <View key={idx}>
                        <Text>{header.name}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              );
            }
            return null;
          })}

          <View
            style={{
              margin: 2,
            }}
          ></View>
          {httpHeaderSecurityData?.bodyInfo?.map((header, index) => {
            if (!header.value) {
              return (
                <View key={index}>
                  <Text style={styles.labelText}>{header.name}</Text>
                  <View
                  style={{
                    margin: 1,
                  }}
                ></View>
                  {(header.highlights?.length > 0 ||
                    header.highlight?.length > 0) && (
                    <View style={[styles.configurationBox, styles.lightGreenLabel]}>
                      <Text style={styles.configurationBoxTitle}>
                        {header.highlights?.length > 0
                          ? header.highlights[0].replace(/\s*\[\d+\]$/, "")
                          : header.highlight?.length > 0
                          ? header.highlight[0].replace(/\s*\[\d+\]$/, "")
                          : ""}
                      </Text>
                      <Text style={styles.configurationBoxBtn}>Good configuration</Text>
                    </View>
                  )}

                  {header.raw && (
                    <View>
                      <Text style={styles.labelText}>{header.name}</Text>
                      <Text style={styles.headerLabel}>{header.raw}</Text>
                    </View>
                  )}

                  {header.directives?.length > 0 && (
                    <View style={styles.directivesTable}>
                      <View>
                        <Text style={styles.tableHeader}>Name</Text>
                        <Text style={styles.tableHeader}>Description</Text>
                        <Text style={styles.tableHeader}>Alerts</Text>
                      </View>
                      {header.directives?.map((directive, idx) => (
                        <View key={idx} style={styles.tableRow}>
                          <Text>{directive.name}</Text>
                          <Text>
                            {directive.description?.replace(/\s*\[\d+\]$/, "")}
                          </Text>
                          <Text>No problems found</Text>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              );
            }
            return null;
          })}
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
