import { useSelector } from "react-redux";

export const useDefaultImageSize = () => {
  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const defaultImageSize = otherSettingsData?.find(
    (item) => item.field_name === "DEFAULT_IMAGE_SIZE"
  )?.field_value;

  return defaultImageSize;
};

export const useDefaultPdfSize = () => {
  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const defaultPdfSize = otherSettingsData?.find(
    (item) => item.field_name === "DEFAULT_PDF_SIZE"
  )?.field_value;

  return defaultPdfSize;
};
