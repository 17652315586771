import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";

//Original one
function AuditLogsInfoModal(props) {
  const { currentRow, ...modalProps } = props;

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  let valueObj = {};

  const properties =
    currentRow.action === "UPDATE"
      ? Object.keys(JSON.parse(currentRow.old_value))
      : Object.keys(JSON.parse(currentRow.new_value));
  if (currentRow.action === "UPDATE") {
    valueObj["oldValue"] = JSON.parse(currentRow.old_value);
    valueObj["newValue"] = JSON.parse(currentRow.new_value);
  } else {
    valueObj = JSON.parse(currentRow.new_value);
  }

  // const parentObject = (obj) => {
  //   return (
  //     <Table striped bordered hover size="sm">
  //       <thead>
  //         <tr>
  //           {obj?.length !== 0 && !Array.isArray(obj) && (
  //             <th>
  //               <b>Property</b>
  //             </th>
  //           )}
  //           <th>
  //             <b>Value</b>
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {obj?.length !== 0 && !Array.isArray(obj)
  //           ? Object.keys(obj)?.map((key, idx) => {
  //               return (
  //                 <tr key={idx}>
  //                   {/* this below line is for make the web_security and notes hiden if it's empty */}
  //                   {obj[key]?.length !== 0 && <td>{key}</td>}
  //                   {key === "assetsWebsites"
  //                     ? obj[key]?.map((item, idx) => {
  //                         return (
  //                           <tr key={idx}>
  //                             <td>
  //                               {/* <a href={item} target="_blank"> */}
  //                               <a
  //                                 href={item}
  //                                 target="_blank"
  //                                 rel="noopener noreferrer"
  //                               >
  //                                 {item}
  //                               </a>
  //                             </td>
  //                           </tr>
  //                         );
  //                       })
  //                     : obj[key]?.length !== 0 &&
  //                       Array.isArray(obj[key]) && (
  //                         <td>{obj[key].join(", ")}</td>
  //                       )}
  //                   {/* obj[key].length !== 0 && <td>{obj[key].join(", ")}</td> */}
  //                 </tr>
  //               );
  //             })
  //           : obj?.map((item, idx) => (
  //               <tr key={idx}>
  //                 <td>{item}</td>
  //               </tr>
  //             ))}
  //       </tbody>
  //     </Table>
  //   );
  // };

  const parentObject = (obj) => {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {obj?.length !== 0 && !Array.isArray(obj) && (
              <th>
                <b>Fields</b>
              </th>
            )}
            <th>
              <b>Fields Value</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {obj?.length !== 0 && !Array.isArray(obj)
            ? Object.keys(obj)?.map((key, idx) => {
                return (
                  <tr key={idx}>
                    {obj[key]?.length !== 0 && (
                      <td>{titleUpperCase(key?.replaceAll("_", " "))}</td>
                    )}
                    {key === "assetsWebsites" ? (
                      <td>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            {obj[key]?.map((item, idx) => (
                              <tr key={idx}>
                                <td>
                                  <a
                                    href={item}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    ) : (
                      obj[key]?.length !== 0 &&
                      Array.isArray(obj[key]) && <td>{obj[key].join(", ")}</td>
                    )}
                  </tr>
                );
              })
            : obj?.map((item, idx) => (
                <tr key={idx}>
                  <td>{item}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    );
  };

  const titleUpperCase = (str) => {
    if (str === "createdAt") {
      str = "created date";
    }
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  return (
    <Modal
      {...modalProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Log Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <b>Fields</b>
              </th>
              {currentRow.action === "UPDATE" && (
                <th>
                  {/* <b>Old Fields Value</b> */}
                  <b>
                    Fields Value (<b style={{ fontWeight: 800 }}>OLD</b>)
                  </b>
                </th>
              )}
              {currentRow.action === "DELETE" ? (
                <th>
                  {/* <b>Old Fields Value</b> */}
                  <b>
                    Fields Value (<b style={{ fontWeight: 800 }}>OLD</b>)
                  </b>
                </th>
              ) : (
                <th>
                  <b>
                    Fields Value (<b style={{ fontWeight: 800 }}>NEW</b>)
                  </b>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {properties?.map((property) => {
              return (
                <tr key={property}>
                  <td>{titleUpperCase(property.replaceAll("_", " "))}</td>
                  {currentRow.old_value && currentRow.new_value ? (
                    <>
                      <td>
                        {valueObj.oldValue[property] !== undefined ? (
                          typeof valueObj.oldValue[property] === "object" ? (
                            parentObject(valueObj.oldValue[property])
                          ) : [
                              "website_guideline_image",
                              "branding_guideline_image",
                            ].includes(property) ? (
                            <img
                              name="departmentImage2"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj.oldValue[property]}`}
                              className="avatar-xl rounded-circle flex-shrink-0 border"
                              alt="user-profile"
                            />
                          ) : [
                              "branding_guideline_pdf",
                              "website_guideline_pdf",
                            ].includes(property) ? (
                            <embed
                              name="developers_profile_pdf"
                              height="100%"
                              width="100%"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj.oldValue[property]}`}
                              alt={property}
                            />
                          ) : (
                            valueObj.oldValue[property]
                          )
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {valueObj.newValue[property] !== undefined ? (
                          typeof valueObj.newValue[property] === "object" ? (
                            parentObject(valueObj.newValue[property])
                          ) : [
                              "website_guideline_image",
                              "branding_guideline_image",
                            ].includes(property) ? (
                            <img
                              name="departmentImage2"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj.newValue[property]}`}
                              className="avatar-xl rounded-circle flex-shrink-0 border"
                              alt="user-profile"
                            />
                          ) : [
                              "branding_guideline_pdf",
                              "website_guideline_pdf",
                            ].includes(property) ? (
                            <embed
                              name="developers_profile_pdf"
                              height="100%"
                              width="100%"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj.newValue[property]}`}
                              alt={property}
                            />
                          ) : (
                            valueObj.newValue[property]
                          )
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </>
                  ) : currentRow.action === "ADD" ? (
                    <td>
                      {valueObj[property] !== undefined ? (
                        typeof valueObj[property] !== "object" ? (
                          [
                            "website_guideline_image",
                            "branding_guideline_image",
                            "image",
                          ].includes(property) ? (
                            <img
                              name="departmentImage2"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj[property]}`}
                              className="avatar-xl rounded-circle flex-shrink-0 border"
                              alt="user-profile"
                            />
                          ) : [
                              "branding_guideline_pdf",
                              "website_guideline_pdf",
                            ].includes(property) ? (
                            <embed
                              name="developers_profile_pdf"
                              height="100%"
                              width="100%"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj[property]}`}
                              alt={property}
                            />
                          ) : ["createdAt"].includes(property) ? (
                            <>
                              {
                                formatDateTime(
                                  valueObj[property],
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedDateFinal
                              }{" "}
                              {
                                formatDateTime(
                                  valueObj[property],
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedTimed
                              }
                            </>
                          ) : (
                            valueObj[property]
                          )
                        ) : (
                          parentObject(valueObj[property])
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  ) : (
                    <td>
                      {valueObj[property] !== null ? (
                        typeof valueObj[property] !== "object" ? (
                          [
                            "website_guideline_image",
                            "branding_guideline_image",
                            "image",
                          ].includes(property) ? (
                            <img
                              name="departmentImage2"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj[property]}`}
                              className="avatar-xl rounded-circle flex-shrink-0 border"
                              alt="user-profile"
                            />
                          ) : [
                              "branding_guideline_pdf",
                              "website_guideline_pdf",
                            ].includes(property) ? (
                            <embed
                              name="developers_profile_pdf"
                              height="100%"
                              width="100%"
                              src={`${process.env.REACT_APP_BASE_URL}${valueObj[property]}`}
                              alt={property}
                            />
                          ) : // valueObj[property]
                          ["createdAt"].includes(property) ? (
                            <>
                              {
                                formatDateTime(
                                  valueObj[property],
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedDateFinal
                              }{" "}
                              {
                                formatDateTime(
                                  valueObj[property],
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedTimed
                              }
                            </>
                          ) : (
                            valueObj[property]
                          )
                        ) : (
                          parentObject(valueObj[property])
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AuditLogsInfoModal;
