import { useCallback, useEffect, useRef, useState } from "react";
import { API_ROUTES } from "../../../../Api/ApiRoutes";
import { Card } from "react-bootstrap";
import { Avatar, Box, Skeleton } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import common from "../../../../Components/Common/common";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";
import { fetchSecurityScanTimeReq } from "../../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import { fetchSecurityScanTimeRes } from "../../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsRes";
import RunScan from "./runScan";

const SecurityCard = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const params = useParams();

  const [securityHistory, setSecurityHistory] = useState([]);
  const [immuniWeb, setImmuniWeb] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [securityHistoryScanTime, setSecurityHistoryScanTime] = useState([]);
  const [immuniLoading, setImmuniLoading] = useState(true);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const immuniWebRef = useRef(immuniWeb);

  useEffect(() => {
    immuniWebRef.current = immuniWeb;
  }, [immuniWeb]);

  const fetchImmuniWeb = useCallback(async () => {
    setImmuniLoading(true);
    try {
      let data = await axios.post(API_ROUTES.GET_IMMUNI_WEB_DATA, {
        assets_id: params.id,
      });
      const decryptedData = common.decrypt(data.data);
      data.data = decryptedData;
      const decryptedMessage = common.decrypt(data.message);
      data.message = decryptedMessage;

      if (data.status === "success") {
        // setUserName(data.userName);
        setImmuniWeb(data.data);
        props.onDataReceived(data.data);
        // setWebPermission(data.message.web_security);
        // setNotesPermission(data.message.notes);
      }
      if (data.status === "fail") {
        return null;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setImmuniLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    fetchImmuniWeb();
  }, [fetchImmuniWeb, refresh]);

  let immuni = (immuniWeb?.summary && JSON.parse(immuniWeb.summary)) || null;

  // Function to extract and sum numeric values from descriptions
  function sumIssues(immuni) {
    let total = 0;

    // Loop through each key in the data object
    for (const key in immuni) {
      if (immuni[key] && typeof immuni[key].description === "string") {
        // Extract numbers from the description
        const match = immuni[key].description.match(/(\d+)/);
        if (match) {
          total += parseInt(match[0], 10);
        }
      }
    }

    return total;
  }

  //Polling is done using the useEffect
  useEffect(() => {
    let intervalId;

    const startPolling = () => {
      intervalId = setInterval(() => {
        if (immuniWebRef.current === null) fetchImmuniWeb();
        else clearInterval(intervalId);
      }, 30000);
    };

    if (props?.assetsData?.isAPIFetched === false) {
      startPolling();
    } else if (props?.assetsData?.isAPIFetched === true && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props?.assetsData?.isAPIFetched, fetchImmuniWeb]);

  const sum = sumIssues(immuni);

  const currentDateTime = new Date();

  let closestData = null;
  let closestTimeDifference = Infinity;

  // Iterate through the array to find the closest data point
  securityHistory.forEach((item) => {
    const createdAtDateTime = new Date(item.createdAt);
    const timeDifference = Math.abs(currentDateTime - createdAtDateTime);

    if (timeDifference < closestTimeDifference) {
      closestTimeDifference = timeDifference;
      closestData = item;
    }
  });
  const userId = props.userId;

  let nearestDate = null;
  let nearestData = null;

  const fetchSecurityScanTime = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityScanTimeReq({
        params,
        userId,
        token,
      });

      let scheduleSecurityTime = await fetch(url, userData);
      let response = await scheduleSecurityTime.json();

      fetchSecurityScanTimeRes({
        response,
        setSecurityHistoryScanTime,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.paramsId, userId, token]);

  securityHistoryScanTime?.forEach((dateTimeString) => {
    const date = new Date(dateTimeString.schedule_date_time);

    if (date > currentDateTime && (!nearestDate || date < nearestDate)) {
      nearestDate = date;
      nearestData = dateTimeString;
    }
  });

  const fetchSecurityHistory = useCallback(async () => {
    try {
      let userData = {
        online_asset_id: props.paramsId,
        user_id: props.userId,
      };
      let url = API_ROUTES.GET_SCHEDULE_SECURITY;
      let reqData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      };
      let schedule = await fetch(url, reqData);
      let data1 = await schedule.json();
      const decryptedData = common.decrypt(data1.data);
      data1.data = decryptedData;
      if (data1.status === "success") {
        setSecurityHistory(data1.data.rows);
      }
      if (data1.status === "fail") {
        console.error("something went wrong please try again later");
      }
    } catch (error) {
      console.error("Something went wrong please try again later");
    }
  }, [props.paramsId, props.userId, token]);

  useEffect(() => {
    fetchSecurityHistory();
    fetchSecurityScanTime();
  }, [fetchSecurityHistory, refresh, fetchSecurityScanTime]);

  const handleRunTestApi = async (paramsId, userId) => {
    try {
      setIsLoading(true);
      await props.refreshTestApi(paramsId, userId);
      // ToastMessage.Success("your date has been updated.");
      setRefresh((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to run test API:", error);
    }
  };

  return (
    <>
      <Card className="main-card">
        <Card.Header>
          <h4 className="card-title">Security</h4>
        </Card.Header>
        <Card.Body>
          {immuniLoading ? (
            <Box className="d-flex flex-column gap-3">
              <Skeleton variant="text" width="40%" height={20} />
              <Skeleton variant="rectangular" height={50} />

              <Box className="d-flex align-content-start justify-content-between gap-1 mt-4">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "30%",
                      height: "150px",
                      margin: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                  >
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width="80%" height={20} />
                    {index === 1 && (
                      <Skeleton variant="text" width="80%" height={20} />
                    )}{" "}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <>
              {typeof immuniWeb?.id === "number" ||
              props?.assetsData?.isAPIFetched === true ? (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fs-16 fw-semibold mb-0">
                      Total Issues - {sum}{" "}
                    </h5>
                  </div>
                  <hr />
                  <p className="fs-16 fw-semibold mb-3">Next security scan</p>
                  <h6 className="fs-16 mb-0">
                    <strong>
                      {nearestData ? (
                        <>
                          {formatDateTime(
                            nearestData?.schedule_date_time,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedDateFinal || "-"}{" "}
                          {formatDateTime(
                            nearestData?.schedule_date_time,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedTimed || "-"}
                        </>
                      ) : (
                        "Date Not Scheduled"
                      )}
                    </strong>
                  </h6>

                  <Box className="d-flex align-content-start justify-content-between gap-1 mt-4">
                    <Box
                      className="pagespeed-card"
                      sx={{ background: "#F6D2CF !important" }}
                    >
                      <Avatar>
                        {!immuniLoading ? (
                          immuni?.score ? (
                            `${immuni?.score}%`
                          ) : (
                            "-"
                          )
                        ) : (
                          <Skeleton variant="circular" width={80} height={80} />
                        )}
                      </Avatar>
                      <h6 className="title fs-14">Score</h6>
                    </Box>
                    <Box className="pagespeed-card">
                      <Avatar>
                        {!immuniLoading ? (
                          immuni?.pci_dss?.description ? (
                            immuni?.pci_dss?.description === "Not Applicable" ||
                            immuni?.pci_dss?.description ===
                              "No Issues Found" ? (
                              0
                            ) : immuni?.pci_dss?.description === "Compliant" ? (
                              "-"
                            ) : (
                              immuni?.pci_dss?.description?.split(" ")[0]
                            )
                          ) : (
                            "-"
                          )
                        ) : (
                          <Skeleton variant="circular" width={80} height={80} />
                        )}
                      </Avatar>
                      <h6 className="title fs-14 mb-3">Issues Found</h6>
                      <h6 className="title fs-14">Pci Dss Issues</h6>
                    </Box>
                    <Box className="pagespeed-card">
                      <Avatar>
                        {!immuniLoading ? (
                          immuni?.grade || "-"
                        ) : (
                          <Skeleton variant="circular" width={80} height={80} />
                        )}
                      </Avatar>
                      <h6 className="title fs-14">Grade</h6>
                    </Box>
                  </Box>
                </>
              ) : (
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
                  <div className="card-body text-center">
                    <h5 className="text-muted">
                      We are in the process of scanning your website. Please be
                      patient as we prepare your report.
                    </h5>
                  </div>
                </div>
              )}
            </>
          )}

          {role && (
            <RunScan
              loading={isLoading}
              onClick={() => handleRunTestApi(props.paramsId, props.userId)}
            />
          )}

          {isLoading && (
            <Box
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
              role="alert"
            >
              Please wait sometimes as we are running a scan
            </Box>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default SecurityCard;
