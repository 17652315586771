import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import DataTable from "react-data-table-component";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LoopIcon from "@mui/icons-material/Loop";
import { Box } from "@mui/material";
import { CustomTableStyle } from "../../../Components/Common/constants";
import common from "../../../Components/Common/common";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDebounce } from "../../EmailLogs/EmailLogs";

const PerformancePage = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  let startDate, endDate;
  if (selectedDate) {
    [startDate, endDate] = selectedDate;
    startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setCurrentPage(1);
      setSelectedDate(selectedDates);
    }
  };

  const fetchData = async (
    startDate = null,
    endDate = null,
    query = "",
    page = 1,
    limit = 10
  ) => {
    // setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_URL}asset/performance-report?&page=${page}&limit=${limit}&searchParams=${query}`;
      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      const data = common.decrypt(responseData.data.data);
      setTotalRowCount(responseData?.data?.totalRecords);
      setCurrentPage(page);
      setData(data); // Assuming the API response contains a 'data' property with an array of performance data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedQueryUrl = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedQueryUrl === query) {
      fetchData(startDate, endDate, debouncedQueryUrl, currentPage, perPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, debouncedQueryUrl, perPage, currentPage]);

  // useEffect(() => {
  //   if (debouncedQueryUrl) {
  //     fetchData(
  //       startDate,
  //       endDate,
  //       debouncedQueryUrl,
  //       currentPage,
  //       perPage
  //     );
  //   }
  // }, [startDate, endDate, debouncedQueryUrl, perPage, currentPage]);

  // useEffect(() => {
  //   if (!query) {
  //     if (startDate && endDate) {
  //       fetchData(
  //         startDate,
  //         endDate,
  //         debouncedQueryUrl,
  //         currentPage,
  //         perPage
  //       );
  //     } else {
  //       fetchData(
  //         startDate,
  //         endDate,
  //         debouncedQueryUrl,
  //         currentPage,
  //         perPage
  //       );
  //     }
  //   }
  // }, [startDate, endDate, debouncedQueryUrl, perPage, currentPage]);

  //2nd approach
  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchData(
  //       startDate,
  //       endDate,
  //       debouncedQueryUrl,
  //       currentPage,
  //       perPage
  //     );
  //   }
  // }, [startDate, endDate, debouncedQueryUrl, perPage, currentPage]);

  // useEffect(() => {
  //   if (!debouncedQueryUrl && !(startDate && endDate)) {
  //     fetchData(
  //       startDate,
  //       endDate,
  //       debouncedQueryUrl,
  //       currentPage,
  //       perPage
  //     );
  //   }
  // }, [startDate, endDate, debouncedQueryUrl, perPage, currentPage]);

  const handleReset = () => {
    setCurrentPage(1);
    setQuery("");
    setSelectedDate(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: <span>Website</span>,
      selector: (row) => (
        <span onClick={() => navigate(`/performance/${row.assets_id}`)}>
          <span className="fw-bold cursor-pointer">{row.url || "-"}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Page Speed</span>,
      selector: (row) => (
        <span className="badge badge-soft-success">
          {row.pagespeed_score || "-"}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Search by URL..."
                value={query}
                onChange={(e) => {
                  setCurrentPage(1);
                  setQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                className="form-control"
                options={{
                  mode: "range",
                  dateFormat: `${dateFormat}`,
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <button
              className="btn btn-primary reset-btn"
              onClick={() => {
                if (currentPage !== 1 || selectedDate || query) {
                  handleReset();
                }
              }}
            >
              <LoopIcon />
            </button>
          </Box>
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            // <DataTable
            //   columns={columns}
            //   data={filteredData} // Use filteredData instead of data
            //   fixedHeader
            //   fixedHeaderScrollHeight="700px"
            //   pagination
            //   customStyles={CustomTableStyle}
            //   className="table-design"
            // />
            <DataTable
              columns={columns}
              data={data} // Use filteredData instead of data
              fixedHeader
              fixedHeaderScrollHeight="700px"
              progressPending={isLoading}
              pagination
              paginationServer
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRowCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PerformancePage;
