import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import axios from "axios";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import TeamCard from "./TeamCard";
import _ from "lodash";
import { DEVELOPER_PROFILE } from "../../../Components/Common/ModuleName";
import { socket } from "../../../Components/Common/socket";
import { Box } from "@mui/material";
import common, { convertMBToBytes } from "../../../Components/Common/common";
import AddDeveloperModal from "./DeveloperModal";
import { useDefaultImageSize } from "../../../Components/Hooks/UseDeafultImageSizeHook";

const Team = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === DEVELOPER_PROFILE
  );

  const [deleteModal, setDeleteModal] = useState(false);
  const [teamList, setTeamlist] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [assetSelectedOptions, setAssetSelectedOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [skillsIds, setAllSkillsIds] = useState("");
  const [postIsLoading, setPostIsLoading] = useState(false);

  useEffect(() => {
    if (location.state?.globalSearch) {
      setQuery(location.state?.globalSearch);
    }
  }, [location.state]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedOptions(selected);
    validation.setFieldValue(
      "skills",
      selected.map(
        (item) => skillsIds.find((option) => option.label === item).value
      )
    );
  };

  const handleAssetsChange = (selectedOptions) => {
    validation.setFieldValue(
      "asset_url_management",
      selectedOptions.map((item) => item.value)
    );
    setAssetSelectedOptions(selectedOptions);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      organization: "",
      contact_no: "",
      email: "",
      organization_url: "",
      designation: "",
      skills: null,
      projects: "",
      tasks: "",
      location: "",
      description: "",
      asset_url_management: null,
      imageFile: null,
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(5)
        .max(255)
        .required("Please enter the name."),
      organization: Yup.string()
        .trim()
        .required("Please enter the organization."),
      contact_no: Yup.string().matches(
        /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
        "Please enter a valid phone number"
      ),
      email: Yup.string()
        .email("Must be a valid email.")
        .max(70)
        .required("Please enter the Email."),
      organization_url: Yup.string()
        .test("is-valid-url", "Invalid URL format", (value) => {
          if (!value) {
            return true;
          }
          const urlPattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          return urlPattern.test(value);
        })
        .required("Please enter the organization URL."),
      asset_url_management: Yup.array()
        .min(1, "Please select at least one website.")
        .required("Please select the webiste."),
      designation: Yup.string().trim().min(5).max(255),
      projects: Yup.number().min(1).max(10000),
      tasks: Yup.number().min(1).max(10000),
      location: Yup.string().trim(),
      description: Yup.string()
        .min(5, "Description must be at least 5 characters.")
        .max(255, "Description must be less than 255 characters."),
      skills: Yup.array()
        .min(1, "Please select at least one skill.")
        .required("Please select the skills."),
    }),

    onSubmit: (values, { resetForm }) => {
      postUserManagement(values);
    },
  });

  const handleClose = () => {
    validation.resetForm();
    setSelectedOptions([]);
    setAssetSelectedOptions([]);
    setShow(false);
  };

  let team = null;

  const handleDeleteTeamData = () => {
    if (team) {
      setDeleteModal(false);
    }
  };

  const postUserManagement = async (values) => {
    setPostIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("organization", values.organization);
      formData.append("user_name", values.name);
      formData.append("contact_no", values.contact_no);
      formData.append("email", values.email);
      formData.append("organization_url", values.organization_url);
      formData.append("designation", values.designation);
      formData.append("skills", values.skills);
      formData.append("location", values.location);
      formData.append("projects", values.projects);
      formData.append("tasks", values.tasks);
      formData.append("description", values.description);
      formData.append("asset_url_management", values.asset_url_management);

      if (values.imageFile && values.imageFile.constructor === File) {
        formData.append("developers_profile_image", values.imageFile);
      }

      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      let url = `${process.env.REACT_APP_BASE_URL}developer-profile/post-developer-profile`;
      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.status === "success") {
        socket.emit("addDeveloperProfile");
        ToastMessage.Success("Developer Profile added Successfully");
      } else {
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      ToastMessage.Error(
        "An error occurred while adding the developer profile."
      );
    } finally {
      setPostIsLoading(false);
      validation.resetForm();
      setSelectedOptions([]);
      setAssetSelectedOptions([]);
      setShow(false);
      navigate("/developer-profile");
      getInternalUsers(1, 5, query);
    }
  };

  const getInternalUsers = useCallback(
    async (page, limit, query, isDelete = false, deleteId = null) => {
      try {
        setLoading(true);
        let userData = {};

        if (isDelete) {
          userData = {
            page: page * limit,
            limit: 1,
          };
        } else {
          userData = { page: page, limit: limit };
        }

        let reqData = {
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile?searchParams=${query}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(userData),
        };

        // Await the axios request to ensure loading state is correctly handled
        const response = await axios.request(reqData);

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;

        if (isDelete) {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            setTeamlist((prevData) => {
              const updatedData = prevData.filter(
                (data) => data.id !== deleteId
              );
              return [...updatedData, ...response.data.rows];
            });
            return response.data;
          } else if (response.status === "fail") {
            setTotalRows(0);
            setTeamlist([]);
          }
        } else {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            setTotalRows(response.data.count);
            setTeamlist(response.data.rows);
          } else if (response.status === "fail") {
            setTotalRows(0);
            setTeamlist([]);
          }
        }
      } catch (error) {
        setTotalRows(0);
        setTeamlist([]);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [navigate, token]
  );

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      getInternalUsers(1, 5, query);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchNextPage = async () => {
    try {
      setLoading(true);

      let userData = { page: page + 1, limit: 5 };

      let reqData = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(userData),
      };

      // Await axios request to ensure completion before moving forward
      const response = await axios.request(reqData);

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        setPage((prevPage) => prevPage + 1);
        return response.data;
      }
    } catch (error) {
      setTotalRows(0);
      setTeamlist([]);
    } finally {
      setLoading(false); // This will now execute after the axios request finishes
    }
  };

  const handleLoadMore = async () => {
    const nextPageOrders = await fetchNextPage();
    setTeamlist((prevData) => [...prevData, ...nextPageOrders.rows]);
    setTotalRows(nextPageOrders?.count);
  };

  const skillsEndPoint = `${process.env.REACT_APP_BASE_URL}skills/get-skills`;

  const getSkills = useCallback(async () => {
    fetch(skillsEndPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const skillIds = responseData?.map((item) => ({
          label: item.skill_name,
          value: item.id,
        }));
        setAllSkillsIds(skillIds);
      });
  }, [skillsEndPoint, token]);

  useEffect(() => {
    getSkills();
  }, [getSkills]);

  const [allAssetIds, setAllAssetIds] = useState("");

  const assetsEndPoint = `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`;

  const getAssets = useCallback(async () => {
    fetch(assetsEndPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const assetsIds = responseData?.map((item) => ({
          label: item.website_url,
          value: item.id,
        }));
        setAllAssetIds(assetsIds);
      });
  }, [assetsEndPoint, token]);

  useEffect(() => {
    getAssets();
  }, [getAssets]);

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE);

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "imageFile",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "imageFile",
          `Failed to upload an image. The image maximum size is ${DEFAULT_IMAGE_SIZE} MB.`
        );
        event.target.value = null;
      } else {
        validation.setFieldError("imageFile", "");
        validation.setFieldValue("imageFile", file);
      }
    }
  };

  return (
    <Box className="developerProfile-page">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Box className="profile-header mb-4">
        <Row className="g-2">
          <Col sm={6} lg={4}>
            <Box className="search-box">
              <Input
                type="text"
                className="search bg-weight border-0"
                placeholder="Search by developer name..."
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
          </Col>
          <Col className="col-sm-auto ms-auto">
            <Box className="list-grid-nav hstack gap-1">
              {hasPermission && hasPermission?.canCreate != null ? (
                <>
                  <Button color="primary" onClick={() => setShow(true)}>
                    <i className="ri-add-fill me-1 align-bottom"></i>Add
                    Developer
                  </Button>
                </>
              ) : null}
            </Box>
          </Col>
        </Row>
      </Box>

      <Box id="teamlist">
        <Box className="row g-3 team-list list-view-filter flex-row">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : teamList?.length > 0 ? (
            <TeamCard
              isLoading={loading}
              totalRows={totalRows}
              handleLoadMore={handleLoadMore}
              query={query}
              teamList={teamList}
              getInternalUsers={getInternalUsers}
              page={page}
              hasPermission={hasPermission}
            />
          ) : (
            <li
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="list-group-item ps-0 pe-0 mt-4 text-center h5"
            >
              No Record Found
            </li>
          )}
        </Box>

        <AddDeveloperModal
          show={show}
          handleClose={handleClose}
          validation={validation}
          postIsLoading={postIsLoading}
          skillsIds={skillsIds}
          selectedOptions={selectedOptions}
          assetSelectedOptions={assetSelectedOptions}
          handleAssetsChange={handleAssetsChange}
          allAssetIds={allAssetIds}
          handleSelectChange={handleSelectChange}
          handleChangeImage={handleChangeImage}
        />
      </Box>
    </Box>
  );
};

export default Team;
