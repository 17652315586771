import { Switch, styled } from "@mui/material";
import CryptoJS from "crypto-js";
import moment from "moment";

let common = {
  decodeHTMLEntities: (text) => {
    const tempElement = document.createElement("textarea");
    tempElement.innerHTML = text;
    return tempElement.value;
  },

  formatNumberWithCommas: (number) => {
    if (isNaN(number)) {
      return "Invalid number";
    }

    const formatter = new Intl.NumberFormat("en-IN");
    return formatter.format(number);
  },

  getColor: (status) => {
    switch (status) {
      case "closed":
        return "rgb(0,189,157)";
      case "active":
        return "rgb(255,188,10)";
      case "pending":
        return "rgb(240,0,0)";
      case "hold":
        return "rgb(156,114,114)";
      default:
        return "";
    }
  },

  downTimeformatDifference: (minutes) => {
    const MINUTES_IN_HOUR = 60;
    const HOURS_IN_DAY = 24;
    const DAYS_IN_MONTH = 30;
    const MONTHS_IN_YEAR = 12;

    // Calculate larger time units
    const hours = Math.floor(minutes / MINUTES_IN_HOUR);
    const days = Math.floor(hours / HOURS_IN_DAY);
    const months = Math.floor(days / DAYS_IN_MONTH);
    const years = Math.floor(months / MONTHS_IN_YEAR);

    // Display as "Hour Minutes", "Day Hours", etc.
    if (years > 0) {
      const remainingMonths = months % MONTHS_IN_YEAR;
      return `${years}y ${remainingMonths}mo`;
    }

    if (months > 0) {
      const remainingDays = days % DAYS_IN_MONTH;
      return `${months}mo ${remainingDays}d`;
    }

    if (days > 0) {
      const remainingHours = hours % HOURS_IN_DAY;
      return `${days}d ${remainingHours}h`;
    }

    if (hours > 0) {
      const remainingMinutes = minutes % MINUTES_IN_HOUR;
      return `${hours}h ${remainingMinutes}m`;
    }

    return `${minutes}m`; // Default to showing minutes if less than an hour
  },

  calculateRemainingDowntimeMinutes: (downtime) => {
    const downtimeDate = new Date(downtime);
    const currentTime = new Date();

    const diffInMilliseconds = Math.abs(currentTime - downtimeDate);
    const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60);

    return diffInMinutes;
  },

  calculateRealTimeDifference: (dateString) => {
    const givenDate = moment(dateString);
    const currentDate = moment();

    const totalSeconds = currentDate.diff(givenDate, "seconds");
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    const totalMonths = Math.floor(totalDays / 30); // Approximate
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const days = totalDays % 30;
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    };
  },

  formatTimeDifference: (difference) => {
    const { years, months, days, hours, minutes, seconds } = difference;
    return `${years > 0 ? `${years}Y ` : ""}${
      months > 0 ? `${months}MO ` : ""
    }${days > 0 ? `${days}D ` : ""}${hours > 0 ? `${hours}H ` : ""}${
      minutes > 0 ? `${minutes}M ` : ""
    }${seconds > 0 ? `${seconds}S` : ""}`.trim();
  },

  formateTime: (timeFormat) => {
    let formattedTimeFormat;
    if (timeFormat === "12") {
      formattedTimeFormat = "H:i";
    } else {
      formattedTimeFormat = "h:i K";
    }
    return formattedTimeFormat;
  },

  encrypt: (plainData) => {
    if (process.env.REACT_APP_ENCRYPTION === "true") {
      try {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(plainData),
          CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERSKEY),
          {
            iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERVIKEY),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7, // Ensure consistent padding
          }
        ).ciphertext.toString(CryptoJS.enc.Hex); // Convert ciphertext to hexadecimal string
        return { data: encryptedData };
      } catch (error) {
        console.error("Encryption error:", error);
        return { error: "Encryption failed" };
      }
    } else {
      return { plainData };
    }
  },

  decrypt: (encryptedData) => {
    if (process.env.REACT_APP_DECRYPTION === "true") {
      const bytes = CryptoJS.AES.decrypt(
        {
          ciphertext: CryptoJS?.enc?.Hex?.parse(encryptedData),
        },
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERSKEY),
        {
          iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERVIKEY),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      if (decryptedData) {
        return JSON.parse(decryptedData);
      } else {
        return null;
      }
    } else {
      return encryptedData;
    }
  },

  validateEmail1: (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validTLDs = [
      "com",
      "co",
      "in",
      "org",
      "net",
      "edu",
      "gov",
      "info",
      "biz",
    ];

    if (!emailRegex.test(email)) {
      return false;
    }

    const [localPart, domain] = email.split("@");

    if (!localPart || !domain) {
      return false;
    }

    if (!domain.includes(".")) {
      return false;
    }

    const domainParts = domain.split(".");
    const topLevelDomain = domainParts.pop();

    if (!validTLDs.includes(topLevelDomain)) {
      return false;
    }

    if (topLevelDomain.length < 2) {
      return false;
    }

    const localPartRegex = /^[a-zA-Z0-9._%+-]+$/;
    if (!localPartRegex.test(localPart)) {
      return false;
    }

    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!domainRegex.test(domain)) {
      return false;
    }

    return true;
  },

  validateEmail: (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the email matches the regex pattern
    if (!emailRegex.test(email)) {
      return false;
    }

    // Split the email into local part and domain
    const [localPart, domain] = email.split("@");

    // Check local part length
    if (localPart.length > 64) {
      return false;
    }

    // Check domain length
    if (domain.length > 255) {
      return false;
    }

    // Check for consecutive dots
    if (email.includes("..")) {
      return false;
    }

    // Check for valid TLDs (you can expand this list)
    const validTLDs = ["com", "org", "net", "edu", "gov", "co"];
    const tld = domain.split(".").pop().toLowerCase();
    if (!validTLDs.includes(tld)) {
      return false;
    }

    return true;
  },

  renderValue: (service, packageServiceData) => {
    if (service.slug === "uptime_report") {
      if (service.value === "true") {
        const interval = packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_interval"
        )?.value;
        const unit = packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_unit"
        )?.value;
        return `${interval} ${unit.toLowerCase()}. monitoring interval`;
      }
      return "Not Available";
    } else if (service.slug === "performance_report") {
      if (service.value === "true") {
        const interval = packageServiceData.find(
          (s) => s.slug === "performance_report_limit"
        )?.value;
        const unit = packageServiceData.find(
          (s) => s.slug === "performance_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "security_scan") {
      if (service.value === "true") {
        const interval = packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit"
        )?.value;
        const unit = packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "analytics_reports") {
      if (service.value === "true") {
        const interval = packageServiceData.find(
          (s) => s.slug === "analytics_services"
        )?.value;

        return `${interval}`;
      }
      return "Not Available";
    }

    if (service.value === null) return "Not Available";
    if (service.value === "true") return "Yes";
    if (service.value === "false") return "No";
    if (Array.isArray(service.value)) return service.value.join(", ");
    return service.value;
  },

  shouldDisplayService: (service) => {
    return ![
      "uptime_monitoring_interval",
      "uptime_monitoring_unit",
      "performance_report_limit",
      "performance_report_limit_unit",
      "security_scan_report_limit",
      "security_scan_report_limit_unit",
      "schedule_security_report_limit",
      "schedule_security_report_limit_unit",
      "analytics_services",
      "custom_package",
    ].includes(service.slug);
  },

  IOSSwitch: styled(({ checked, onChange, ...props }) => (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        checked={checked}
        onChange={onChange}
        {...props}
      />
    </div>
  ))(({ theme }) => ({
    width: 60, // Increase the width to accommodate larger labels if necessary
    height: 34, // Increase the height
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 4, // Adjust margin if needed
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(26px)", // Adjust translateX for increased width
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 28, // Increase thumb size
      height: 28, // Increase thumb size
    },
    "& .MuiSwitch-track": {
      borderRadius: 34 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  })),

  LOGOTOOLTIP:
    "Only JPEG, JPG, PNG, and SVG images are allowed. The maximum size of the image is 1MB. Recommended image dimensions are 353px in width and 174px in height.",
  PDFTOOLTIP:
    "Only PDF Documents are allowed. Maximum size of the documents is 15MB.",
  PACKAGEINACTIVE: "Your package is inactive, please contact your admin.",
  ADMINMESSAGE: "Admin services are paused. Please check back shortly.",
  USERLIMITMESSAGE:
    "You have reached the maximum limit of the current plan for adding a new user. Please upgrade your plan.",
  USERPACKAGEEXPIREMESSAGE:
    "Your plan has expired. Please renew your plan to continue adding new users.",
  ASSETLIMITMESSAGE:
    "You have reached the maximum limit of the current plan for adding a new asset. Please upgrade your plan.",
  ASSETPACKAGEEXPIREMESSAGE:
    "Your plan has expired. Please renew your plan to continue adding new assets.",
  ISADMINASSIGNE:
    "You cannot delete this admin because they are assigned to user.",
  ISSUPERADMINMESSAGE: "Super Admin Cannot be editable.",
  SCHEDULESECURITYEXPIREMESSAGE:
    "Your plan has expired. Please renew your plan to continue scheduling security scans.",
  SCHEDULESECURITYLIMITEMESSAGE:
    "Your limit has been reached. Please upgrade your plan to schedule more scans.",
  SCHEDULESECURITYNOTAVAILABLE:
    "Scheduling security scan is not available in your current plan. Please upgrade to access this feature.",
};

export function defaultImageSizeTooltip(message) {
  return `Only JPEG, JPG, PNG, and SVG images are allowed. Maximum size of the Image is ${message} MB.`;
}

export function decodeAsciiEntities(input) {
  return input.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec); // Convert the numeric value to its character equivalent
  });
}

export function convertMBToBytes(sizeInMB) {
  const parsedSize = parseInt(sizeInMB, 10);

  if (isNaN(parsedSize) || parsedSize <= 0) {
    throw new Error("Invalid input: Please provide a positive numeric value.");
  }

  return parsedSize * 1024 * 1024;
}

export default common;
