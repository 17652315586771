import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CoverPasswCreate = () => {
  const navigate = useNavigate();

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [error, setError] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const rToken = queryParameters.get("token");

  const updatePassword = async (values) => {
    let userData = {
      token: rToken,
      password: values.password,
    };

    let reqData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };

    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}user/update-password`,
      reqData
    );
    let response = await result.json();

    if (response.status === "success") {
      ToastMessage.Success(response.message);

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
    if (response.status === "fail") {
      setError(response.message);
      ToastMessage.Success(response.message);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least one lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least one uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password Required"),
    }),
    onSubmit: (values) => {
      updatePassword(values);
    },
  });

  return (
    <React.Fragment>
      <div className="right-side">
        <div>
          <h5 className="main-title text-primary">Create password</h5>
          <p class="main-subtitle fs-20">Enter a new password below to change your password.</p>
          <p className="text-muted">{error}</p>
        </div>
        <div className="form-container">
          <Form onSubmit={validation.handleSubmit}>
            <div className="mb-4">
              <Label className="form-label" htmlFor="password">Password</Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  type={passwordShow ? "text" : "password"}
                  className="form-control pe-5 password-input"
                  placeholder="Enter password"
                  id="password-input"
                  name="password"
                  value={validation.values.password}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.password && validation.touched.password
                      ? true
                      : false
                  }
                />
                {validation.errors.password && validation.touched.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
                <Button
                  color="link"
                  onClick={() => setPasswordShow(!passwordShow)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                  id="password-addon"
                >
                  {passwordShow ? <VisibilityOff /> : <Visibility />}
                </Button>
              </div>
            </div>

            <div className="mb-3">
              <Label className="form-label" htmlFor="confirm-password-input">Confirm Password</Label>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <Input
                  type={confirmPasswordShow ? "text" : "password"}
                  className="form-control pe-5 password-input"
                  placeholder="Confirm Password"
                  id="confirm-password-input"
                  name="confirm_password"
                  value={validation.values.confirm_password}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.errors.confirm_password &&
                    validation.touched.confirm_password
                      ? true
                      : false
                  }
                />

                {validation.errors.confirm_password &&
                validation.touched.confirm_password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirm_password}
                  </FormFeedback>
                ) : null}
                <Button
                  color="link"
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                >
                  {confirmPasswordShow ? <VisibilityOff /> : <Visibility />}
                </Button>
              </div>
            </div>

            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
              <h5 className="fs-13">Password must contain:</h5>
              <p id="pass-length" className="invalid fs-12 mb-2">
                Minimum <b>8 characters</b>
              </p>
              <p id="pass-lower" className="invalid fs-12 mb-2">
                At <b>lowercase</b> letter (a-z)
              </p>
              <p id="pass-upper" className="invalid fs-12 mb-2">
                At least <b>uppercase</b> letter (A-Z)
              </p>
              <p id="pass-number" className="invalid fs-12 mb-0">
                A least <b>number</b> (0-9)
              </p>
            </div>

            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="auth-remember-check"
              />
              <Label className="form-check-label" htmlFor="auth-remember-check">
                Remember me
              </Label>
            </div>

            <div className="mt-4">
              <Button className="w-100 submit-btn btn-dark btn btn-primary" type="submit">Reset Password</Button>
            </div>
          </Form>
        </div>

        <div className="mt-5 text-center">
          <p className="mb-0">
            Wait, I remember my password...{" "}
            <Link
              to="/auth-signin-cover"
              className="fw-semibold text-primary text-decoration-underline"
            >
              {" "}
              Click here{" "}
            </Link>{" "}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CoverPasswCreate;
