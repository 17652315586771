import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import FormatDate from "../../../../Components/Common/formatDate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import dvdlHome from "../../../../assets/images/dvdl-home.png";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import ProfileImage from "../../../../Components/Common/ProfileImage";
import { Link } from "react-router-dom";
import TextEditor from "../../../../Components/Common/TextEditor";
import _ from "lodash";
import {
  PostNotesReq,
  getNotesReq,
} from "../../../../Api/Comments&Notes/Comments&NotesReq";
import { PostNotesRes } from "../../../../Api/Comments&Notes/Comments&NotesRes";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import "./CommentsAndNotes.scss";
import {
  Avatar,
  Box,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SendIcon from "@mui/icons-material/Send";
import common from "../../../../Components/Common/common";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";

const CommentsAndNotes = (props) => {
  const { id, onNotesAdded, userId } = props;
  const token = localStorage.getItem("token");

  const [searchNotes, setSearchNotes] = useState("");
  const [notes, setNotes] = useState([]);
  const [notesData, setNotesData] = useState("");
  const [modal, setModal] = useState(false);
  const [commentsData, setCommentsData] = useState("");
  const [selectedDays, setSelectedDays] = useState("all");
  const [commentsVisible, setCommentsVisible] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickDays = (days) => {
    setSelectedDays(days);
  };

  const postNotes = async () => {
    try {
      if (notesData === "") {
        ToastMessage.Error("Please write the note.");
        return;
      } else if (notesData.length < 15) {
        ToastMessage.Error("Notes content should be minimum 15 characters");
        return;
      } else {
        const { url, reqData } = PostNotesReq({ notesData, token, userId, id });

        let result = await fetch(url, reqData);
        let response = await result.json();

        PostNotesRes({
          response,
          ToastMessage,
          getNotes,
          searchNotes,
          onNotesAdded,
          setModal,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotes = useCallback(
    async (days, searchNotes) => {
      try {
        setIsLoading(true);
        const { url, reqData } = getNotesReq({ id, days, searchNotes, token });

        const result = await fetch(url, reqData);
        const response = await result.json();

        const decryptedData = common.decrypt(response.data);

        if (response.status === "success") {
          setNotes(decryptedData);
          setIsLoading(false);
        }
        if (response.code === "fail") {
          ToastMessage.Error(response.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
    [id, token]
  );

  useEffect(() => {
    const debouncedGetNotes = _.debounce(() => {
      getNotes(selectedDays, searchNotes);
    }, 500);

    debouncedGetNotes();

    return () => {
      debouncedGetNotes.cancel();
    };
  }, [getNotes, selectedDays, searchNotes]);
  // useEffect(() => {
  //   getNotes(selectedDays, searchNotes);
  // }, [getNotes, selectedDays, searchNotes]);

  // useEffect(() => {
  //   const debouncedFetchData = _.debounce(() => {
  //     getNotes(null, searchNotes);
  //   }, 500);

  //   debouncedFetchData();

  //   return () => {
  //     debouncedFetchData.cancel();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchNotes]);

  const postComments = async (notesId) => {
    try {
      if (commentsData.length > 5) {
        // setIsSubmitting(true); // Disable the button
        let userData = {
          user_id: props?.userId,
          online_asset_id: props.id,
          comments: commentsData,
          notes_id: notesId,
        };
        let url = `${process.env.REACT_APP_BASE_URL}website-management/post-comments`;
        let reqData = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        };

        let result = await fetch(url, reqData);
        let response = await result.json();
        if (response.status === "success") {
          getNotes(null, searchNotes);
          props.onNotesAdded();
          ToastMessage.Success("Comment added successfully");
        }
        if (response.status === "fail") {
          ToastMessage.Error(response.message);
        }
      } else {
        ToastMessage.Error("Comments should be minimum 5 characters");
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setIsSubmitting(false); // Enable the button again after the request is complete
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const filteredNotes = notes.filter((note) =>
    note.notes.toLowerCase().includes(searchNotes.toLowerCase())
  );

  const times = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const statusOptions = [
    { value: "1", label: "Today" },
    { value: "7", label: "Last 7 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "365", label: "Last 365 Days" },
    { value: "all", label: "All Notes" },
  ];

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const handleNoteChange = (data) => {
    setNotesData(data);

    // Clear previous errors when data changes
    setError("");
  };

  const handleSubmit = () => {
    if (notesData.length < 15) {
      setError("The note must be at least 15 characters.");
    } else if (notesData.length > 255) {
      setError("The note must not exceed 255 characters.");
    } else {
      setError(""); // Clear error if validation passes
      postNotes(notesData); // Proceed with the submission
      setNotesData(""); // Clear the editor
      setModal(false); // Close the modal
    }
  };

  return (
    <Box className="comment-page">
      <Box className="d-flex align-items-center justify-content-between mb-3 gap-2 flex-wrap">
        <Box className="d-flex align-items-center flex-wrap gap-4">
          <Box className="search-box">
            <input
              type="text"
              className="form-control"
              placeholder="Search for notes"
              spellCheck="false"
              data-ms-editor="true"
              onChange={(e) => setSearchNotes(e.target.value)}
            />
            <i className="ri-search-line search-icon"></i>
          </Box>

          <FormControl
            fullWidth
            variant="filled"
            sx={{
              width: "175px",
              display: "inline-block",
            }}
          >
            <Select
              displayEmpty
              fullWidth
              value={selectedDays}
              onChange={(e) => handleClickDays(e.target.value)}
              sx={{
                "& .MuiSelect-select": {
                  paddingTop: "9px",
                  background: "transparent",
                  fontSize: "14px",
                },
                "&.MuiInputBase-root": {
                  borderRadius: "8px",
                  background: "#fff",
                  "&::before, &::after": {
                    borderBottom: "0 !important",
                  },
                },
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {props?.notesPermission?.notes?.includes("execute") ||
        props?.userRole === "Super Admin" ? (
          <>
            <Button
              variant="primary"
              onClick={() => {
                setModal(true);
              }}
            >
              Create Note
            </Button>
          </>
        ) : (
          <> </>
        )}
      </Box>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <>
          <List>
            {notes.map((item, index) => (
              <ListItem key={index} className="card mb-3 p-4">
                <Box className="d-flex w-100">
                  <Box className="flex-shrink-0 profile">
                    {isLoading ? (
                      <Skeleton variant="circular" width={50} height={50} />
                    ) : (
                      <>
                        {item.user.user_image ? (
                          item.user.user_image != null ? (
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}${item.user.user_image}`}
                              alt="profile"
                              className="profile-img"
                            />
                          ) : (
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}${item.user.user_image}`}
                              className="profile-img"
                              alt="profile"
                            />
                          )
                        ) : (
                          <ProfileImage username={item.user.user_name} />
                        )}
                      </>
                    )}
                  </Box>
                  <Box className="flex-grow-1 ms-3">
                    {isLoading ? (
                      <>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="text" width={70} height={30} />
                        </div>
                      </>
                    ) : (
                      <>
                        <h5>
                          {item.user.user_name}

                          <small className="text-muted ms-3 fs-12">
                            {item.createdAt ? (
                              <>
                                {
                                  formatDateTime(
                                    item.createdAt,
                                    dateFormat,
                                    timeZone,
                                    timeFormat
                                  ).timeZone.formattedDateFinal
                                }{" "}
                                {
                                  formatDateTime(
                                    item.createdAt,
                                    dateFormat,
                                    timeZone,
                                    timeFormat
                                  ).timeZone.formattedTimed
                                }
                              </>
                            ) : (
                              <>00:00</>
                            )}
                          </small>
                        </h5>
                      </>
                    )}
                    <Box className="cms images_small">
                      {isLoading ? (
                        <>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Skeleton variant="text" width={300} height={30} />
                          </div>
                        </>
                      ) : (
                        <>
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: item.notes,
                            }}
                          />
                        </>
                      )}
                    </Box>

                    <Box>
                      {item.comments
                        .slice(0, 1)
                        .map((comment, commentIndex) => (
                          <Box key={commentIndex} className="mt-3">
                            <Box className="d-flex">
                              <Box className="flex-shrink-0 profile">
                                {isLoading ? (
                                  <Skeleton
                                    variant="circular"
                                    width={50}
                                    height={50}
                                  />
                                ) : (
                                  <>
                                    {comment.user.user_image != null ? (
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${comment.user.user_image}`}
                                        className="profile-img"
                                        alt="profile"
                                      />
                                    ) : (
                                      <ProfileImage
                                        username={comment.user.user_name}
                                      />
                                    )}
                                  </>
                                )}
                              </Box>
                              <Box className="flex-grow-1 ms-3">
                                {isLoading ? (
                                  <>
                                    <div
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <Skeleton
                                        variant="text"
                                        width={150}
                                        height={30}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width={70}
                                        height={30}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <h5>
                                      {comment.user.user_name}

                                      <small className="text-muted ms-3 fs-12">
                                        {comment.createdAt ? (
                                          <>
                                            {" "}
                                            {
                                              formatDateTime(
                                                comment.createdAt,
                                                dateFormat,
                                                timeZone,
                                                timeFormat
                                              ).timeZone.formattedDateFinal
                                            }{" "}
                                            {
                                              formatDateTime(
                                                comment.createdAt,
                                                dateFormat,
                                                timeZone,
                                                timeFormat
                                              ).timeZone.formattedTimed
                                            }
                                          </>
                                        ) : (
                                          <>00:00</>
                                        )}
                                      </small>
                                    </h5>
                                  </>
                                )}

                                <Box className="cms images_small">
                                  {isLoading ? (
                                    <>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        <Skeleton
                                          variant="text"
                                          width={300}
                                          height={30}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Box
                                        dangerouslySetInnerHTML={{
                                          __html: comment.comments,
                                        }}
                                      />
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}

                      {commentsVisible[item.id] && (
                        <Box className="mt-3">
                          {item.comments
                            .slice(1)
                            .map((comment, commentIndex) => (
                              <Box key={commentIndex} className="d-flex mt-3">
                                <Box className="flex-shrink-0 profile">
                                  {isLoading ? (
                                    <Skeleton
                                      variant="circular"
                                      width={50}
                                      height={50}
                                    />
                                  ) : (
                                    <>
                                      {comment.user.user_image ? (
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}${comment.user.user_image}`}
                                          className="profile-img"
                                          alt="profile"
                                        />
                                      ) : (
                                        <ProfileImage
                                          username={comment.user.user_name}
                                        />
                                      )}
                                    </>
                                  )}
                                </Box>
                                <Box className="flex-grow-1 ms-3">
                                  {isLoading ? (
                                    <>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={30}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width={70}
                                          height={30}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <h5>
                                        {comment.user.user_name}

                                        <small className="text-muted ms-3 fs-12">
                                          {comment.createdAt ? (
                                            <>
                                              {" "}
                                              {FormatDate(
                                                comment.createdAt
                                              )}{" "}
                                              {times(comment.createdAt)}
                                            </>
                                          ) : (
                                            <>00:00</>
                                          )}
                                        </small>
                                      </h5>
                                    </>
                                  )}
                                  <Box className="cms images_small">
                                    {isLoading ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            variant="text"
                                            width={300}
                                            height={30}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Box
                                          dangerouslySetInnerHTML={{
                                            __html: comment.comments,
                                          }}
                                        />
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                        </Box>
                      )}

                      {item.comments.length > 1 && (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {commentsVisible[item.id]
                                ? "Hide Comments"
                                : "View All Comments"}
                            </Tooltip>
                          }
                        >
                          <Link
                            className="badge text-muted bg-light"
                            style={{
                              padding: "0px 4px 0px 4px",
                              borderRadius: "10px",
                              marginLeft: "7px",
                            }}
                            onClick={() => {
                              setCommentsVisible({
                                ...commentsVisible,
                                [item.id]: !commentsVisible[item.id],
                              });
                            }}
                          >
                            {commentsVisible[item.id] ? (
                              <MoreHorizOutlinedIcon
                                sx={{ fontSize: "20px" }}
                              />
                            ) : (
                              <MoreHorizOutlinedIcon
                                sx={{ fontSize: "20px" }}
                              />
                            )}
                          </Link>
                        </OverlayTrigger>
                      )}
                    </Box>
                  </Box>
                </Box>

                <a
                  className="btn btn-primary btn-sm mt-4 me-auto d-flex align-items-center gap-1 px-3"
                  data-bs-toggle="collapse"
                  href={`#collapseExample${item.id}`}
                  role="button"
                  aria-expanded="true"
                  aria-controls="collapseExample"
                >
                  <AddRoundedIcon /> Add Comment
                </a>

                <Box
                  className="collapse mt-3 w-100"
                  id={`collapseExample${item.id}`}
                >
                  <form>
                    <Box>
                      <TextEditor
                        value={commentsData}
                        onChange={(data) => {
                          setCommentsData(data);
                        }}
                        filename="comment-attechment"
                        apiUrl={`${process.env.REACT_APP_BASE_URL}website-management/post-comments/attechments`}
                      />

                      <button
                        type="button"
                        className="btn btn-dark ms-auto mt-2 send-btn"
                        onClick={() => {
                          const collapseElement = document.getElementById(
                            `collapseExample${item.id}`
                          );
                          if (collapseElement.classList.contains("show")) {
                            collapseElement.style.height = "0px";
                            collapseElement.classList.remove("show");
                          }

                          postComments(item.id);
                          setCommentsData("");
                        }}
                      >
                        Submit <SendIcon />
                      </button>
                    </Box>
                  </form>

                  <Modal
                    className="fade show"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdropLabel"
                    aria-modal="true"
                    style={{
                      display: "block",
                      paddingLeft: "0px",
                    }}
                  >
                    <Box
                      className="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <Box className="modal-content">
                        <Box className="modal-body text-center p-3">
                          <img
                            src={dvdlHome}
                            alt="DVDL"
                            width="100%"
                            className="border-bottom"
                          />
                          <Box className="mt-4">
                            <Box className="hstack gap-2 justify-content-center">
                              <Button variant="secondary">Close</Button>{" "}
                              <Link
                                href="#"
                                target="#"
                                className="btn btn-primary"
                              >
                                Visit Website
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {filteredNotes.length === 0 && (
        <Card className="text-center fs-16" style={{ padding: "130px 40px" }}>
          No notes found.
        </Card>
      )}

      <Modal show={modal} centered>
        <Modal.Header onClick={toggle}>
          <h5 className="mb-0">Create Note</h5>
        </Modal.Header>
        <Modal.Body>
          <Box className="ck-editor">
            <TextEditor
              value={notesData}
              onChange={handleNoteChange}
              filename="notes-attechment"
              apiUrl={`${process.env.REACT_APP_BASE_URL}website-management/post-notes/attechments`}
            />
          </Box>
          <Box className="mt-4">
            {error ? (
              <div style={{ color: "red" }}>{error}</div>
            ) : (
              <div style={{ color: "red" }}>
                The note must be at least 15 characters and no more than 255
                characters.
              </div>
            )}
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={
              handleSubmit
              //   () => {
              //   postNotes();
              //   setNotesData("");
              // }
            }
          >
            Submit {/*  <SendRoundedIcon /> */}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setModal(false);
              setError("");
              setNotesData("");
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default CommentsAndNotes;
