// TicketModal.js

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap"; // Assuming you are using reactstrap
import { useFormik } from "formik";
// import Select from "react-select";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { convertMBToBytes } from "../../../../Components/Common/common";
import { MenuHeight } from "../../constant";
import { useDefaultImageSize } from "../../../../Components/Hooks/UseDeafultImageSizeHook";

const TicketModal = ({
  show,
  onHide,
  handleModalClose,
  projectOptions,
  dashboardAnalyticAddTicket,
  websiteGroupOptions,
  assetsData,
}) => {
  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();
  const LOGOTOOLTIP = `Only JPEG, JPG, PNG, and SVG images are allowed. The maximum size of the image is ${DEFAULT_IMAGE_SIZE} MB. Recommended image dimensions are 353px in width and 174px in height.`;

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      priority: "",
      ticket: null,
      department_id: "",
    },

    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(5, "Title must be a 5 charcter long")
        .max(50, "Title must be a 50 charcter long")
        .required("Please enter the title."),
      description: Yup.string()
        .min(10, "Discription must be a 10 charcter long")
        .required("Please enter the description."),
      priority: Yup.string().required("Please select the priority."),
      department_id: Yup.string().required("Please select website category."),
      ticket: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          `File size is too large, file should be max ${DEFAULT_IMAGE_SIZE} MB.`,
          (value) => {
            return (
              !value ||
              (value && value.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE))
            );
          }
        )
        .test(
          "fileType",
          "Invalid file type.  Only JPEG, JPG, PNG files are allowed. Please upload a file with one of these formats.",
          (value) => {
            return (
              !value ||
              (value &&
                ["image/jpeg", "image/jpg", "image/png"].includes(value.type))
            );
          }
        ),
    }),

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await dashboardAnalyticAddTicket(values);
        handleModalClose();
        formik.resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleCloseClick = () => {
    handleModalClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (assetsData?.department_id) {
      formik.setFieldValue("department_id", assetsData.department_id);
    }
  }, [assetsData?.department_id, show]);

  return (
    <Modal fade={true} isOpen={show} toggle={handleModalClose} centered={true}>
      <ModalBody>
        <Form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            {/* <label>Title *</label> */}
            <TextField
              fullWidth
              name="title"
              id="outlined-size-small"
              label="Title *"
              placeholder="Enter the ticket title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // invalid={
              //   formik.errors.title && formik.touched.title ? true : false
              // }
            />
            {formik.errors.title && formik.touched.title ? (
              <span className="error-text">{formik.errors.title}</span>
            ) : null}
          </div>
          <div className="mb-3">
            <TextField
              fullWidth
              label="Description *"
              variant="outlined"
              id="outlined-size-small"
              name="description"
              placeholder="Enter Short Description"
              value={formik.values.description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              multiline
              rows={2} // You can adjust the number of rows as needed
              // error={
              //   formik.touched.description && Boolean(formik.errors.description)
              // }
            />
            {formik.errors.description && formik.touched.description && (
              <span className="error-text">{formik.errors.description}</span>
            )}
          </div>
          <Box className="mb-3">
            <FormControl fullWidth>
              <InputLabel>Website category *</InputLabel>
              <Select
                label="Website category *"
                fullWidth
                value={
                  websiteGroupOptions.find(
                    (option) => option.id === formik.values.department_id
                  )?.id || ""
                }
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  formik.setFieldValue("department_id", selectedValue);
                }}
                MenuProps={MenuHeight}
                renderValue={(selected) => {
                  if (!selected) {
                    return <>Website category</>;
                  }
                  return websiteGroupOptions.find(
                    (option) => option.id === selected
                  )?.label;
                }}
                disabled={!!assetsData?.department_id} // Disable if assetsData.department_id exists
              >
                {websiteGroupOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.errors.department_id && formik.touched.department_id && (
              <span className="error-text">{formik.errors.department_id}</span>
            )}
          </Box>
          <div className="mb-3">
            <FormControl fullWidth>
              <InputLabel id="priority-label">Select an priority *</InputLabel>
              <Select
                labelId="priority-label"
                id="priority"
                name="priority"
                value={formik.values.priority}
                onChange={(event) => {
                  formik.setFieldValue("priority", event.target.value);
                }}
                onBlur={formik.handleBlur}
                label="Select an priority *"
              >
                {projectOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.errors.priority && formik.touched.priority ? (
              <span style={{ color: "red" }} className="d-block">
                {formik.errors.priority}
              </span>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="image">
              Upload Image{" "}
              <Tooltip title={LOGOTOOLTIP} placement="top-start" arrow>
                <i
                  className="ri-information-line fs-18 cursor-pointer ms-2"
                  style={{
                    color: "#ced4da",
                  }}
                ></i>
              </Tooltip>
            </label>
            <Input
              className="form-control"
              type="file"
              id="ticket"
              name="ticket"
              onChange={(event) => {
                formik.setFieldValue("ticket", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              invalid={formik.errors.ticket && formik.touched.ticket}
            />
            {formik.errors.ticket && formik.touched.ticket && (
              <FormFeedback type="invalid" style={{ color: "red" }}>
                {formik.errors.ticket}
              </FormFeedback>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => handleCloseClick()}
            >
              Cancel
            </Button>
            <Button className="btn btn-dark ms-3" type="submit">
              {isLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default TicketModal;
