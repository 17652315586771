import * as types from "./actionType";

export const fetchSiteStatusRequest = (id) => ({
  type: types.FETCH_SITE_STATUS_REQUEST,
  payload: id,
});

export const fetchSiteStatusSuccess = (data) => ({
  type: types.FETCH_SITE_STATUS_SUCCESS,
  payload: data,
});

export const fetchSiteStatusFailure = (error) => ({
  type: types.FETCH_SITE_STATUS_FAILURE,
  payload: error,
});

// Filtered Data

export const fetchFilteredDataRequest = (
  id,
  filter,
  customStartDate,
  customEndDate
) => ({
  type: types.FETCH_FILTERED_DATA_REQUEST,
  payload: { id, filter, customStartDate, customEndDate },
});

export const fetchFilteredDataSuccess = (data) => ({
  type: types.FETCH_FILTERED_DATA_SUCCESS,
  payload: data,
});

export const fetchFilteredDataFailure = (error) => ({
  type: types.FETCH_FILTERED_DATA_FAILURE,
  payload: error,
});
